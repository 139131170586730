import {
  BodyText,
  Content,
  Heading,
  IconSearch,
  IconCheckmark,
  IconAlert,
  IconClock,
} from '@lmig/lmds-react';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import constants from '@lmig-latam/adcl-common-lib/constants/constants';
import { SettingsActions } from '../../actions';
import { AppFooter, AppHeader, CircleImage } from '../../components';
import './styles.scss';
import localizedStrings from '../../utils/localizedStrings';

const {
  INVALID_STATUS_AGENT_ERROR_MESSAGE_TITLE,
  INVALID_STATUS_AGENT_ERROR_MESSAGE_SUBTITLE,
  INVALID_STATUS_APPROVED_ERROR_MESSAGE_TITLE,
  INVALID_STATUS_APPROVED_ERROR_MESSAGE_SUBTITLE,
  INVALID_STATUS_REJECTED_ERROR_MESSAGE_TITLE,
  INVALID_STATUS_REJECTED_ERROR_MESSAGE_SUBTITLE,
  INVALID_STATUS_EXPIRED_ERROR_MESSAGE_TITLE,
  INVALID_STATUS_EXPIRED_ERROR_MESSAGE_SUBTITLE,
  ERROR_MESSAGE_TITLE,
  ERROR_MESSAGE_SUBTITLE,
} = localizedStrings;

const {
  STATUS_TYPES: {
    STATUS_AGENT_REVIEW,
    STATUS_AGENT_IN_PROGRESS,
    STATUS_AGENT_APPROVED,
    STATUS_AGENT_REJECTED,
    STATUS_EXPIRED,
  },
} = constants;

const getConfig = status => {
  switch (status) {
    case STATUS_AGENT_IN_PROGRESS:
    case STATUS_AGENT_REVIEW:
      return {
        title: INVALID_STATUS_AGENT_ERROR_MESSAGE_TITLE,
        subtitle: INVALID_STATUS_AGENT_ERROR_MESSAGE_SUBTITLE,
        icon: <IconSearch sizing="auto" />,
      };
    case STATUS_AGENT_APPROVED:
      return {
        title: INVALID_STATUS_APPROVED_ERROR_MESSAGE_TITLE,
        subtitle: INVALID_STATUS_APPROVED_ERROR_MESSAGE_SUBTITLE,
        icon: <IconCheckmark sizing="auto" />,
      };
    case STATUS_AGENT_REJECTED:
      return {
        title: INVALID_STATUS_REJECTED_ERROR_MESSAGE_TITLE,
        subtitle: INVALID_STATUS_REJECTED_ERROR_MESSAGE_SUBTITLE,
        icon: <IconAlert sizing="auto" />,
      };
    case STATUS_EXPIRED:
      return {
        title: INVALID_STATUS_EXPIRED_ERROR_MESSAGE_TITLE,
        subtitle: INVALID_STATUS_EXPIRED_ERROR_MESSAGE_SUBTITLE,
        icon: <IconClock sizing="auto" />,
      };
    default:
      return {
        title: ERROR_MESSAGE_TITLE,
        subtitle: ERROR_MESSAGE_SUBTITLE,
        icon: <IconAlert sizing="auto" />,
      };
  }
};

const InvalidStatus = ({
  invalidStatus,
  settingsActions: { hideAppLoader },
}) => {
  // Hide App Loader
  // (In case it was displayed prior to navigating here)
  hideAppLoader();

  const { title, subtitle, icon } = getConfig(invalidStatus);

  return (
    <div className="adcl-screen">
      <AppHeader displaySubtitle />
      <Content className="adcl-content invalid-status-content">
        <CircleImage>{icon}</CircleImage>
        <Heading type="h4-light" className="auto-left-right-margin">
          {title}
        </Heading>
        <BodyText className="gray-text">{subtitle}</BodyText>
      </Content>
      <AppFooter />
    </div>
  );
};

InvalidStatus.propTypes = {
  settingsActions: SettingsActions.PropertyTypes.isRequired,
  invalidStatus: PropTypes.string,
};

InvalidStatus.defaultProps = {
  invalidStatus: 'default',
};

const mapStateToProps = ({ settings: { invalidStatus } }) => ({
  invalidStatus,
});

const mapDispatchToProps = dispatch => ({
  settingsActions: bindActionCreators(SettingsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvalidStatus);
