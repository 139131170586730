import { Button, Content, GridCol, GridRow, Heading } from '@lmig/lmds-react';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { connect } from 'react-redux';
import { AppFooter, AppHeader } from '../../components';
import { getCountryCode, isOutsideBusinessHours } from '../../utils';
import { ProcessCompleteActions } from '../../actions';
import localizedStrings from '../../utils/localizedStrings';
import customerComplete from './customer-complete.svg';

import './styles.scss';

const {
  PROCESS_COMPLETE_TITLE,
  PROCESS_COMPLETE_SUBTITLE,
  PROCESS_COMPLETE_SUBTITLE_OUTSIDE_BUSINESS_HOURS,
  PROCESS_COMPLETE_CONTINUE_BUTTON,
} = localizedStrings;

const ProcessComplete = ({ processCompleteRedirectUrl }) => {
  const submit = async () => {
    // Navigate to Claims Portal
    window.location.href = processCompleteRedirectUrl;
  };

  useEffect(() => {
    ProcessCompleteActions.deleteAllCookies();
  }, []);

  const renderFooterContents = () => (
    <GridRow className="process-complete-footer">
      <GridCol className="align-center">
        <Button type="button" onClick={submit}>
          {PROCESS_COMPLETE_CONTINUE_BUTTON}
        </Button>
      </GridCol>
    </GridRow>
  );

  return (
    <div className="adcl-screen">
      <AppHeader displaySubtitle />
      <Content className="adcl-content process-complete">
        <Heading type="h3-light" className="auto-left-right-margin">
          {PROCESS_COMPLETE_TITLE}
        </Heading>
        <Heading
          type="h4-light"
          className="process-complete-subtitle auto-left-right-margin"
        >
          {isOutsideBusinessHours()
            ? ReactHtmlParser(PROCESS_COMPLETE_SUBTITLE_OUTSIDE_BUSINESS_HOURS)
            : ReactHtmlParser(PROCESS_COMPLETE_SUBTITLE)}
        </Heading>
        <img src={customerComplete} alt="Liberty Seguros" />
        {renderFooterContents()}
      </Content>
      <AppFooter />
    </div>
  );
};

ProcessComplete.propTypes = {
  processCompleteRedirectUrl: PropTypes.string.isRequired,
};

const mapStateToProps = ({ settings: { environment } }) => {
  const { processCompleteRedirectUrl } = environment[getCountryCode()];
  return {
    processCompleteRedirectUrl,
  };
};

export default connect(mapStateToProps, null)(ProcessComplete);
