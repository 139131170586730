import {
  BodyText,
  Button,
  Caption,
  Content,
  Heading,
  IconAlert,
  IconCheckmark,
  IconClock,
} from '@lmig/lmds-react';
import classNames from 'classnames';
import { Form, Text } from 'informed';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PhoneNumberConfirmationActions, SettingsActions } from '../../actions';
import {
  AppFooter,
  AppHeader,
  CircleImage,
  InlineLoader,
  Modal,
} from '../../components';
import { SMS_LOCKOUT } from '../../utils/constants';
import localizedStrings from '../../utils/localizedStrings';
import { TERMS_CONDITIONS } from '../../utils/navigationConstants';
import { navigate } from '../../utils/NavigationUtils';
import cellphoneandmessage from './cellphoneandmessage.svg';
import './styles.scss';
import history from '../../utils/history';

const {
  PHONE_NUMBER_CONFIRMATION_TITLE,
  PHONE_NUMBER_CONFIRMATION_SUBTITLE,
  PHONE_NUMBER_CONFIRMATION_IMAGE_ALT_TEXT,
  PHONE_NUMBER_CONFIRMATION_INPUT_PLACEHOLDER,
  PHONE_NUMBER_CONFIRMATION_INPUT_VALIDATION_MESSAGE,
  PHONE_NUMBER_CONFIRMATION_INCORRECT_CODE,
  PHONE_NUMBER_CONFIRMATION_CODE_NOT_RECEIVED,
  PHONE_NUMBER_CONFIRMATION_CODE_RESEND_LIMIT_REACHED_MODAL_TITLE,
  PHONE_NUMBER_CONFIRMATION_CODE_RESEND_LIMIT_REACHED_MODAL_SUBTITLE,
  PHONE_NUMBER_CONFIRMATION_CODE_RESEND_LIMIT_REACHED_MODAL_TITLE_PRIMARY_BUTTON_LABEL,
  PHONE_NUMBER_CONFIRMATION_RESEND_CODE,
  PHONE_NUMBER_CONFIRMATION_RESEND_CODE_SUCCESS,
  PHONE_NUMBER_CONFIRMATION_RESEND_CODE_FAILURE_MODAL_TITLE,
  PHONE_NUMBER_CONFIRMATION_RESEND_CODE_FAILURE_MODAL_SUBTITLE,
  PHONE_NUMBER_CONFIRMATION_RESEND_CODE_FAILURE_MODAL_PRIMARY_BUTTON_LABEL,
  PHONE_NUMBER_CONFIRMATION_CONTINUE_BUTTON,
  PHONE_NUMBER_CONFIRMATION_LOADING_TEXT,
  AUTH_TOKEN_SESSION_TIMED_OUT,
} = localizedStrings;

const PhoneNumberConfirmation = ({
  phoneNumberConfirmationActions: {
    resendSmsConfirmationCode,
    validateEmailTokenAndGenerateSmsConfirmationCode,
    validateSmsConfirmationCode,
  },
  settingsActions: { showAppLoader },
  user: { maskedCellPhoneNumber },
}) => {
  const [formApi, setFormApi] = useState();

  const [
    resendSmsConfirmationCodeInlineLoaderDisplayed,
    setResendSmsConfirmationCodeInlineLoaderDisplayed,
  ] = useState(false);

  const [
    resendSmsConfirmationCodeSucceeded,
    setResendSmsConfirmationCodeSucceeded,
  ] = useState(false);

  const [
    resendSmsConfirmationCodeFailureModalDisplayed,
    setResendSmsConfirmationCodeFailureModalDisplayed,
  ] = useState(false);

  const [
    resendSmsConfirmationCodeSendLimitModalDisplayed,
    setResendSmsConfirmationCodeSendLimitModalDisplayed,
  ] = useState(false);

  const [
    submitFormInlineLoaderDisplayed,
    setSubmitFormInlineLoaderDisplayed,
  ] = useState(false);

  useEffect(() => {
    showAppLoader();

    validateEmailTokenAndGenerateSmsConfirmationCode();
  }, [showAppLoader, validateEmailTokenAndGenerateSmsConfirmationCode]);

  const setInformedApi = informedApi => {
    setFormApi(informedApi);
  };

  const showResendSmsConfirmationCodeFailureModal = () => {
    setResendSmsConfirmationCodeFailureModalDisplayed(true);
  };

  const hideResendSmsConfirmationCodeFailureModal = () => {
    setResendSmsConfirmationCodeFailureModalDisplayed(false);
  };

  const showResendSmsConfirmationCodeSendLimitModal = () => {
    setResendSmsConfirmationCodeSendLimitModalDisplayed(true);
  };

  const hideResendSmsConfirmationCodeSendLimitModal = () => {
    setResendSmsConfirmationCodeSendLimitModalDisplayed(false);
  };

  const navigateToTermsConditions = () => {
    navigate(TERMS_CONDITIONS);
  };

  const resendCode = async () => {
    setResendSmsConfirmationCodeInlineLoaderDisplayed(true);

    setResendSmsConfirmationCodeSucceeded(false);

    try {
      await resendSmsConfirmationCode();

      setResendSmsConfirmationCodeSucceeded(true);
    } catch (error) {
      showResendSmsConfirmationCodeFailureModal();
    } finally {
      setResendSmsConfirmationCodeInlineLoaderDisplayed(false);
    }
  };

  const retryResendSmsConfirmationCode = () => {
    hideResendSmsConfirmationCodeFailureModal();
    resendCode();
  };

  const getSmsErrorMessage = errorCode =>
    errorCode === SMS_LOCKOUT ? '' : PHONE_NUMBER_CONFIRMATION_INCORRECT_CODE;

  const submitForm = async () => {
    setSubmitFormInlineLoaderDisplayed(true);

    try {
      await validateSmsConfirmationCode(
        formApi.getValue('smsConfirmationCode'),
      );

      navigateToTermsConditions();
    } catch ({ code }) {
      if (code === SMS_LOCKOUT) {
        showResendSmsConfirmationCodeSendLimitModal();
      }

      formApi.setError('smsConfirmationCode', getSmsErrorMessage(code));

      setSubmitFormInlineLoaderDisplayed(false);
    }
  };

  const validateSmsFieldConfirmationCode = value => {
    const isInvalid =
      !value || value < 100000 || value > 999999 || `${value}`.length !== 6;
    return isInvalid
      ? PHONE_NUMBER_CONFIRMATION_INPUT_VALIDATION_MESSAGE
      : undefined; // Should default to undefined if validation passes
  };

  const renderResendSmsFailureModal = () => (
    <Modal
      isOpen={resendSmsConfirmationCodeFailureModalDisplayed}
      primaryButtonLabel={
        PHONE_NUMBER_CONFIRMATION_RESEND_CODE_FAILURE_MODAL_PRIMARY_BUTTON_LABEL
      }
      primaryButtonHandler={retryResendSmsConfirmationCode}
      backButtonHandler={hideResendSmsConfirmationCodeFailureModal}
      centerContent
    >
      <div className="phone-number-confirmation-modal">
        <Heading type="h3-light" className="auto-left-right-margin">
          {PHONE_NUMBER_CONFIRMATION_RESEND_CODE_FAILURE_MODAL_TITLE}
        </Heading>
        <BodyText>
          {PHONE_NUMBER_CONFIRMATION_RESEND_CODE_FAILURE_MODAL_SUBTITLE}
        </BodyText>
        <div className="phone-number-confirmation-modal-image auto-left-right-margin">
          <CircleImage>
            <IconAlert sizing="auto" />
          </CircleImage>
        </div>
      </div>
    </Modal>
  );

  const renderResendSmsSendLimitModal = () => (
    <Modal
      isOpen={resendSmsConfirmationCodeSendLimitModalDisplayed}
      primaryButtonLabel={
        PHONE_NUMBER_CONFIRMATION_CODE_RESEND_LIMIT_REACHED_MODAL_TITLE_PRIMARY_BUTTON_LABEL
      }
      primaryButtonHandler={hideResendSmsConfirmationCodeSendLimitModal}
      backButtonHandler={hideResendSmsConfirmationCodeSendLimitModal}
      centerContent
    >
      <div className="sms-code-send-limit-modal">
        <div className="image">
          <CircleImage>
            <IconClock sizing="auto" />
            <div className="tiny-circle">
              <IconAlert color="inverse" className="icon" sizing="auto" />
            </div>
          </CircleImage>
        </div>
        <Heading type="h4-light" className="wrap">
          {PHONE_NUMBER_CONFIRMATION_CODE_RESEND_LIMIT_REACHED_MODAL_TITLE}
        </Heading>
        <BodyText className="wrap">
          {PHONE_NUMBER_CONFIRMATION_CODE_RESEND_LIMIT_REACHED_MODAL_SUBTITLE}
        </BodyText>
      </div>
    </Modal>
  );

  const renderFooterContents = () => (
    <div className="phone-number-confirmation-footer">
      {renderResendSmsFailureModal()}
      {renderResendSmsSendLimitModal()}
      <Form onSubmit={submitForm} getApi={setInformedApi}>
        {({ formState }) => {
          const disableSubmitButton =
            formState.pristine ||
            !formState.values.smsConfirmationCode ||
            `${formState.values.smsConfirmationCode}`.length !== 6 ||
            submitFormInlineLoaderDisplayed;

          return (
            <div>
              <Text
                field="smsConfirmationCode"
                pattern="[0-9]*"
                maxLength={6}
                inputMode="numeric"
                autoComplete="off"
                validate={validateSmsFieldConfirmationCode}
                placeholder={PHONE_NUMBER_CONFIRMATION_INPUT_PLACEHOLDER}
                className={classNames('phone-number-confirmation-input', {
                  'input-field-error': formState.errors.smsConfirmationCode,
                })}
              />

              {formState.errors.smsConfirmationCode && (
                <Caption className="error-message phone-number-confirmation-error-message auto-left-right-margin">
                  <IconAlert />
                  {formState.errors.smsConfirmationCode}
                </Caption>
              )}

              {resendSmsConfirmationCodeInlineLoaderDisplayed ? (
                <div className="phone-number-confirmation-resend-sms-loader">
                  <InlineLoader isLoading />
                </div>
              ) : (
                <Caption className="phone-number-confirmation-code-caption">
                  {resendSmsConfirmationCodeSucceeded && (
                    <span className="phone-number-confirmation-resend-sms-confirmation-code-success">
                      <IconCheckmark
                        color="inverse"
                        className="phone-number-confirmation-resend-sms-confirmation-code-success-icon"
                      />
                      {PHONE_NUMBER_CONFIRMATION_RESEND_CODE_SUCCESS}
                    </span>
                  )}
                  <span className="phone-number-confirmation-code-not-received">
                    {PHONE_NUMBER_CONFIRMATION_CODE_NOT_RECEIVED}
                  </span>
                  <span
                    onClick={resendCode}
                    role="link"
                    className="phone-number-confirmation-resend-code"
                  >
                    {PHONE_NUMBER_CONFIRMATION_RESEND_CODE}
                  </span>
                </Caption>
              )}

              <div className="phone-number-confirmation-button-container">
                <Button disabled={disableSubmitButton} type="submit">
                  {submitFormInlineLoaderDisplayed
                    ? PHONE_NUMBER_CONFIRMATION_LOADING_TEXT
                    : PHONE_NUMBER_CONFIRMATION_CONTINUE_BUTTON}
                </Button>
              </div>
            </div>
          );
        }}
      </Form>
    </div>
  );

  return (
    <div className="adcl-screen">
      <AppHeader displaySubtitle />
      {history.location.state && history.location.state.sessionTimeout && (
        <div className="phone-number-confirmation-session-timeout-container">
          <div className="phone-number-confirmation-session-timeout-icon-container">
            <IconAlert color="inverse" className="icon" size="24" />
          </div>
          <div className="phone-number-confirmation-session-timeout-text-container">
            <BodyText className="phone-number-confirmation-session-timeout">
              {AUTH_TOKEN_SESSION_TIMED_OUT}
            </BodyText>
          </div>
        </div>
      )}
      <Content className="adcl-content">
        <CircleImage>
          <img
            src={cellphoneandmessage}
            className="phone-number-confirmation-icon"
            alt={PHONE_NUMBER_CONFIRMATION_IMAGE_ALT_TEXT}
          />
        </CircleImage>
        <Heading
          type="h3-light"
          className="phone-number-confirmation-title auto-left-right-margin"
        >
          {PHONE_NUMBER_CONFIRMATION_TITLE}
        </Heading>
        <BodyText className="phone-number-confirmation-subtitle">
          {PHONE_NUMBER_CONFIRMATION_SUBTITLE}
        </BodyText>
        <div>{maskedCellPhoneNumber}</div>
      </Content>
      {renderFooterContents()}
      <AppFooter />
    </div>
  );
};
PhoneNumberConfirmation.propTypes = {
  phoneNumberConfirmationActions:
    PhoneNumberConfirmationActions.PropertyTypes.isRequired,
  settingsActions: SettingsActions.PropertyTypes.isRequired,
  user: PropTypes.shape({
    maskedCellPhoneNumber: PropTypes.string.isRequired,
  }).isRequired,
};

const mapStateToProps = ({ user }) => ({
  user,
});

const mapDispatchToProps = dispatch => ({
  phoneNumberConfirmationActions: bindActionCreators(
    PhoneNumberConfirmationActions,
    dispatch,
  ),
  settingsActions: bindActionCreators(SettingsActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PhoneNumberConfirmation);
