import { DELETE_PHOTO, STORE_PHOTO } from '../actions/ActionTypes';

export const initialState = {
  requiredPhotosTaken: [],
  optionalPhotosTaken: [],
};

const getCorrectPhotoArrayObject = (
  { requiredPhotosTaken, optionalPhotosTaken },
  isOptional,
) => {
  let photoArray;
  let photoArrayName;

  if (!isOptional) {
    photoArray = requiredPhotosTaken;
    photoArrayName = 'requiredPhotosTaken';
  }

  if (isOptional) {
    photoArray = optionalPhotosTaken;
    photoArrayName = 'optionalPhotosTaken';
  }

  return { photoArray, photoArrayName };
};

const getStateWithUpdatedPhoto = (
  state,
  photoArrayName,
  filteredPhotoArray,
  photoObject,
) => ({
  ...state,
  [photoArrayName]: [...filteredPhotoArray, photoObject],
});

const storePhotoHandler = (state, action) => {
  const { photo: incomingPhoto } = action;
  const { isOptional } = incomingPhoto;

  const { photoArray, photoArrayName } = getCorrectPhotoArrayObject(
    state,
    isOptional,
  );

  const filteredPhotoArray = photoArray.filter(
    filteredPhoto => filteredPhoto.photoId !== incomingPhoto.photoId,
  );

  return getStateWithUpdatedPhoto(
    state,
    photoArrayName,
    filteredPhotoArray,
    incomingPhoto,
  );
};

const deletePhotoHandler = (state, action) => {
  const { photoId: incomingPhotoId } = action;

  return {
    optionalPhotosTaken: state.optionalPhotosTaken.filter(
      ({ photoId }) => photoId !== incomingPhotoId,
    ),
    requiredPhotosTaken: state.requiredPhotosTaken.filter(
      ({ photoId }) => photoId !== incomingPhotoId,
    ),
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case STORE_PHOTO:
      return storePhotoHandler(state, action);
    case DELETE_PHOTO:
      return deletePhotoHandler(state, action);
    default:
      return state;
  }
};
