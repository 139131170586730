import PropTypes from 'prop-types';
import { deleteCookie } from '../utils';
import {
  EMAIL_TOKEN_COOKIE_NAME,
  AUTH_TOKEN_COOKIE_NAME,
  RETAKE_DATA_COOKIE_NAME,
  RESUME_DATA_COOKIE_NAME,
} from '../utils/constants';

export default {
  PropertyTypes: PropTypes.shape({
    deleteAllCookies: PropTypes.func.isRequired,
  }),

  deleteAllCookies() {
    deleteCookie(EMAIL_TOKEN_COOKIE_NAME);
    deleteCookie(AUTH_TOKEN_COOKIE_NAME);
    deleteCookie(RETAKE_DATA_COOKIE_NAME);
    deleteCookie(RESUME_DATA_COOKIE_NAME);
  },
};
