import { BodyText, Content, Heading, IconAlert } from '@lmig/lmds-react';
import React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { SettingsActions } from '../../actions';
import { AppFooter, AppHeader, CircleImage } from '../../components';
import localizedStrings from '../../utils/localizedStrings';
import './styles.scss';

const { ERROR_MESSAGE_TITLE, ERROR_MESSAGE_SUBTITLE } = localizedStrings;

const GenericError = ({ settingsActions: { hideAppLoader } }) => {
  const {
    state: {
      title = ERROR_MESSAGE_TITLE,
      subtitle = ERROR_MESSAGE_SUBTITLE,
    } = {},
  } = useLocation();

  // Hide App Loader
  // (In case it was displayed prior to navigating here)
  hideAppLoader();

  return (
    <div className="adcl-screen">
      <AppHeader displaySubtitle />
      <Content className="adcl-content generic-error-content">
        <CircleImage>
          <IconAlert sizing="auto" />
        </CircleImage>
        <Heading type="h4-light" className="auto-left-right-margin">
          {title}
        </Heading>
        <BodyText className="gray-text">{subtitle}</BodyText>
      </Content>
      <AppFooter />
    </div>
  );
};

GenericError.propTypes = {
  settingsActions: SettingsActions.PropertyTypes.isRequired,
};

const mapDispatchToProps = dispatch => ({
  settingsActions: bindActionCreators(SettingsActions, dispatch),
});

export default connect(null, mapDispatchToProps)(GenericError);
