import constants from '@lmig-latam/adcl-common-lib/constants/constants';
import 'url-search-params-polyfill';
import { UPDATE_RETAKE, UPDATE_RESUME } from '../actions/ActionTypes';
import { isLocalhost } from '../config/environments';
import { store } from './configureStore';
import { compressImage } from './imageCompression';
import {
  EMAIL_TOKEN_COOKIE_NAME,
  RETAKE_DATA_COOKIE_NAME,
  RESUME_DATA_COOKIE_NAME,
  TOKEN_TIMEOUT,
} from './constants';
import localizedStrings from './localizedStrings';

export const getCountryCode = () =>
  isLocalhost()
    ? constants.LANGUAGES.CL
    : window.location.host.split('.').pop();

// Taken from this answer: https://stackoverflow.com/a/11381730
// Which leads here: http://detectmobilebrowsers.com/
export const isMobileOrTablet = () => {
  const { userAgent } = navigator;
  return (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
      userAgent,
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
      userAgent.substr(0, 4),
    )
  );
};

const getClosingTime = () => {
  const {
    settings: { environment },
  } = store.getState();

  const { businessHoursEnd } = environment[getCountryCode()].businessHours;

  const closingTime = new Date();

  closingTime.setHours(businessHoursEnd);
  closingTime.setMinutes(0);
  closingTime.setSeconds(0);
  closingTime.setMilliseconds(0);

  return closingTime;
};

const getOpeningTime = () => {
  const {
    settings: { environment },
  } = store.getState();

  const { businessHoursStart } = environment[getCountryCode()].businessHours;

  const openingTime = new Date();

  openingTime.setHours(businessHoursStart);
  openingTime.setMinutes(0);
  openingTime.setSeconds(0);
  openingTime.setMilliseconds(0);

  return openingTime;
};

const getCurrentTime = () => new Date();

// TODO: Figure out how to do this for specific locales
export const isOutsideBusinessHours = () => {
  const {
    settings: { environment },
  } = store.getState();

  const {
    businessHoursEnabled,
    weekendsAreOutsideOfBusinessHours,
  } = environment[getCountryCode()].businessHours;

  const openingTime = getOpeningTime();
  const closingTime = getClosingTime();
  const currentTime = getCurrentTime();

  // If businessHoursEnabled is false, then we are ALWAYS inside business hours
  if (!businessHoursEnabled) {
    return false;
  }

  const todaysDayOfWeek = new Date().getDay();

  // If weekends defined as outside of business hours, and today is a Saturday or Sunday,
  // then we are outside of business hours
  if (
    weekendsAreOutsideOfBusinessHours &&
    (todaysDayOfWeek === 0 || todaysDayOfWeek === 6)
  ) {
    return true;
  }

  return !(currentTime >= openingTime && currentTime <= closingTime);
};

/**
 * Will map an object from this:
 *
 * {
 *    photoId: 'example photo id',
 *    title: 'Example Photo Title',
 *    ...
 *    ...
 * }
 *
 * to this:
 *
 * {
 *    photoId: 'EXAMPLE_PHOTO_ID',
 *    title: 'Example Photo Title',
 *    ...
 *    ...
 * }
 *
 * i.e. uppercases the title and replaces spaces with underscores
 */
export const formatPhotoConfig = config =>
  config.map(photo => ({
    ...photo,
    photoId: photo.photoId.toUpperCase().replace(/ /gi, '_'),
  }));

export const getSinglePhotoConfig = (requestedId, config) =>
  config.filter(({ photoId }) => photoId === requestedId)[0];

// Taken from https://www.w3schools.com/js/js_cookies.asp
export const getCookie = cookieName => {
  const name = `${cookieName}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');

  for (let i = 0; i < cookieArray.length; i += 1) {
    let cookie = cookieArray[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return '';
};

// Taken from https://www.w3schools.com/js/js_cookies.asp
// Note: If no expiry is provided, the cookie will expire with the session
export const setCookie = (cookieName, cookieValue, expiryInHours = null) => {
  let expires = '';
  if (expiryInHours) {
    const now = new Date();
    now.setTime(now.getTime() + expiryInHours * 60 * 60 * 1000);
    expires = `expires=${now.toUTCString()}`;
  }
  document.cookie = `${cookieName}=${cookieValue};${expires};path=/`;
};

// Taken from https://stackoverflow.com/a/2144404
export const deleteCookie = cookieName => {
  if (getCookie(cookieName)) {
    document.cookie = `${cookieName}=;expires=Thu, 01 Jan 1970 00:00:01 GMT`;
  }
};

export const createEmailTokenCookie = () => {
  const emailToken = new URLSearchParams(window.location.search).get('token');
  if (emailToken) {
    // Set email cookie expiry to 2 hours. Same as the auth token expiry
    setCookie(EMAIL_TOKEN_COOKIE_NAME, emailToken, TOKEN_TIMEOUT);
  }
};

export const createRetakeConfigCookie = () => {
  const unparsedRetakeConfig = getCookie(RETAKE_DATA_COOKIE_NAME);

  if (unparsedRetakeConfig) {
    const parsedRetakeConfig = JSON.parse(unparsedRetakeConfig);

    store.dispatch({
      type: UPDATE_RETAKE,
      value: parsedRetakeConfig,
    });
  }
};

export const createResumeConfigCookie = () => {
  const unparsedResumeConfig = getCookie(RESUME_DATA_COOKIE_NAME);

  if (unparsedResumeConfig) {
    const parsedResumeConfig = JSON.parse(unparsedResumeConfig);

    store.dispatch({
      type: UPDATE_RESUME,
      value: parsedResumeConfig,
    });
  }
};

export const setAppTitle = () => {
  document.title = localizedStrings.BROWSER_TAB_TITLE;
};

export const listenForBackButton = () => {
  // TODO make me work
  // this does catch the back event, but I didn't figure out how to cancel the navigation and replace it with ERROR_GENERIC or DEFAULT
  // https://stackoverflow.com/questions/39342195/intercept-handle-browsers-back-button-in-react-router
  /*
  history.listen((location, action) => {
    if (action === HISTORY_POP) {
      try {
        checkForTokens();
      } catch (error) {
        handleTokenMissingError(error);

        // history.replace(ERROR_GENERIC);
      }
    }
  });
  */
};

export const setupAppConfigs = () => {
  setAppTitle();
  createRetakeConfigCookie();
  createResumeConfigCookie();
  createEmailTokenCookie();
  listenForBackButton();
};

export const generatePhotoObjects = async (
  incomingFile,
  photoId,
  isOptional,
  isConditionallyRequired,
) => {
  // Image to be shown to customer
  const photoToDisplay = {
    blobUrl: await compressImage(incomingFile, photoId, 720),
    fileType: 'jpeg',
    photoId,
    isOptional,
    isConditionallyRequired,
  };

  // Image to be uploaded
  const photoToUpload = {
    blobUrl: await compressImage(incomingFile, photoId),
    photoId,
  };

  return { photoToDisplay, photoToUpload };
};
