import { getCookie, createEmailTokenCookie } from '..';
import history from '../history';
import { logcodes, logger } from '../logger';
import {
  DEFAULT,
  ERROR_GENERIC,
  PROCESS_COMPLETE_ORIGINAL,
  PROCESS_COMPLETE,
} from '../navigationConstants';
import { AUTH_TOKEN_COOKIE_NAME, EMAIL_TOKEN_COOKIE_NAME } from '../constants';
import localizedStrings from '../localizedStrings';

const {
  EMAIL_TOKEN_ERROR_MESSAGE_TITLE,
  EMAIL_TOKEN_ERROR_MESSAGE_SUBTITLE,
} = localizedStrings;

class TokenError extends Error {
  constructor(message, ...params) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, TokenError);
    }

    this.message = message;
    this.name = 'TokenError';
  }
}

const handleTokenMissingError = tokenError => {
  // no need to handle the error if we're already on ERROR_GENERIC
  if (history.location.pathname === ERROR_GENERIC) {
    return;
  }

  let newPath;
  let state;

  const { push } = history;

  if (tokenError.message === logcodes.CANAV110.code) {
    newPath = ERROR_GENERIC;
    state = {
      title: EMAIL_TOKEN_ERROR_MESSAGE_TITLE,
      subtitle: EMAIL_TOKEN_ERROR_MESSAGE_SUBTITLE,
    };

    logger.log(logcodes.CANAV110, {
      to: ERROR_GENERIC,
      from: history.location.pathname,
    });
  } else if (
    tokenError.message === logcodes.CANAV100.code ||
    tokenError.message === logcodes.CANAV120.code
  ) {
    newPath = DEFAULT;
    state = { sessionTimeout: true };
    const code = tokenError.message;
    logger.log(logcodes[code], {
      to: DEFAULT,
      from: history.location.pathname,
    });
  }

  // if any of the above code has resulted in a newPath then we should navigate
  if (newPath) {
    window.scrollTo(0, 0);

    push(newPath, state);
  }
};

const checkForEmailToken = () => {
  // attempt to recover emailToken cookie from URL
  createEmailTokenCookie();

  const isAvailable = !!getCookie(EMAIL_TOKEN_COOKIE_NAME);

  if (!isAvailable) {
    throw new TokenError(logcodes.CANAV110.code);
  }
};

const checkForAuthToken = () => {
  if (history.location.pathname === DEFAULT) {
    return;
  }

  const isAvailable = !!getCookie(AUTH_TOKEN_COOKIE_NAME);

  if (!isAvailable) {
    throw new TokenError(logcodes.CANAV100.code);
  }
};

const checkForTokens = () => {
  // authToken and emailToken don't matter on the complete screen
  if (
    history.location.pathname === PROCESS_COMPLETE_ORIGINAL ||
    history.location.pathname === PROCESS_COMPLETE
  ) {
    return;
  }

  checkForEmailToken(); // emailToken must be checked first since it takes precedence
  checkForAuthToken();
};

export {
  checkForTokens,
  checkForAuthToken,
  checkForEmailToken,
  handleTokenMissingError,
  TokenError,
};
