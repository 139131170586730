export const SMS_LOCKOUT = 'VSMS200';

export const HISTORY_POP = 'POP';

export const EMAIL_TOKEN_COOKIE_NAME = 'emailToken';
export const AUTH_TOKEN_COOKIE_NAME = 'authToken';
export const RETAKE_DATA_COOKIE_NAME = 'retakeData';
export const RESUME_DATA_COOKIE_NAME = 'resumeData';

export const TOKEN_TIMEOUT = 24; // Hours
