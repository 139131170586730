import { GridCol, GridRow } from '@lmig/lmds-react';
import PropTypes from 'prop-types';
import React from 'react';
import { PhotoPreviewPanel } from '..';

const renderPhotoPreviewPanel = (
  photo,
  navigate,
  previewPhoto,
  deletePhoto,
) => (
  <GridRow key={photo.photoId}>
    <GridCol>
      <PhotoPreviewPanel
        {...photo}
        navigate={navigate}
        previewPhoto={previewPhoto}
        deletePhoto={deletePhoto}
        displayRetakePhotoButton={false}
      />
    </GridCol>
  </GridRow>
);

const OptionalPhotoList = ({ navigate, photos, deletePhoto }) => (
  <div>
    {photos.map(accessoryPhoto => {
      const photo = photos.filter(
        element => element.photoId === accessoryPhoto.photoId,
      )[0];
      const previewPhoto = photo ? photo.blobUrl : '';

      return renderPhotoPreviewPanel(
        accessoryPhoto,
        navigate,
        previewPhoto,
        deletePhoto,
      );
    })}
  </div>
);

OptionalPhotoList.propTypes = {
  navigate: PropTypes.func.isRequired,
  photos: PropTypes.arrayOf(PropTypes.object).isRequired,
  deletePhoto: PropTypes.func.isRequired,
};

export default OptionalPhotoList;
