import ImageCompressor from 'compressorjs';
import { fileUtils } from '@lmig-latam/adlib-ui';
import { logcodes, logger } from './logger';

const { getBlobUrlFromBlobFile } = fileUtils;

export const compressImage = (photoFile, photoId, maxResolution = 1080) => {
  const startTime = Date.now();
  const originalPhotoSize = photoFile.size;
  const checkOrientation = originalPhotoSize > 3000000;

  return new Promise((resolve, reject) => {
    const options = {
      maxWidth: maxResolution,
      maxHeight: maxResolution,
      quality: 0.8,
      checkOrientation,
      convertSize: 'infinity',
      mimeType: 'image/jpeg',
      beforeDraw() {
        logger.log(logcodes.CAIMG020, {
          name: photoId,
          originalPhotoSize,
          ram: navigator.deviceMemory || 'UNKNOWN',
          type: photoFile.type,
          userAgent: navigator.userAgent,
        });
      },
      success: compressedImage => {
        const blobUrl = getBlobUrlFromBlobFile(compressedImage);
        const compressedPhotoSize = compressedImage.size;
        const timeElapsed = Date.now() - startTime;

        logger.log(logcodes.CAIMG010, {
          name: photoId,
          timeElapsed,
          originalPhotoSize,
          compressedPhotoSize,
        });

        resolve(blobUrl);
      },
      error: ({ message, stack }) => {
        logger.log(logcodes.CAIMG110, {
          name: photoId,
          message,
          stack,
        });

        reject();
      },
    };
    new ImageCompressor(photoFile, options); // eslint-disable-line no-new
  });
};

export default compressImage;
