import { fileUtils } from '@lmig-latam/adlib-ui';
import { PhotoHubActions } from '../../actions';
import { requiredPhotosPhotoIdList } from '../../config/requiredPhotos';
import { store } from '../configureStore';
import { HIDE_APP_LOADER, SHOW_APP_LOADER } from '../../actions/ActionTypes';
import localizedStrings from '../localizedStrings';
import { generatePhotoObjects } from '..';
import {
  image01,
  image02,
  image03,
  image04,
  image05,
  image06,
  image07,
  image08,
} from './images';

const { getBlobFileFromUrl } = fileUtils;
const { PHOTO_HUB_LOAD_TEST_IMAGES_PROGRESS } = localizedStrings;

const getTestPhotoObject = async (url, index) => {
  const blobFile = await getBlobFileFromUrl(url);
  return generatePhotoObjects(
    blobFile,
    requiredPhotosPhotoIdList[index],
    false,
    false,
  );
};

export const getMultipleRealPhotos = () =>
  Promise.all(
    [
      image01,
      image02,
      image03,
      image04,
      image05,
      image06,
      image07,
      image08,
    ].map(getTestPhotoObject),
  );

export const loadTestImages = async () => {
  store.dispatch({
    type: SHOW_APP_LOADER,
    translucent: true,
    message: PHOTO_HUB_LOAD_TEST_IMAGES_PROGRESS,
  });

  const promises = [];
  const realPhotosList = await getMultipleRealPhotos();

  realPhotosList
    .filter(({ photoToUpload }) => !!photoToUpload.photoId)
    .forEach(({ photoToUpload, photoToDisplay }) => {
      promises.push(
        store.dispatch(
          PhotoHubActions.uploadPhoto(photoToUpload, photoToDisplay),
        ),
      );
    });

  await Promise.all(promises);

  store.dispatch({
    type: HIDE_APP_LOADER,
  });
};

export const singleMockPhoto = {
  photoId: requiredPhotosPhotoIdList[0],
  blobUrl: 'MOCK_PHOTO_STRING',
};

export const photoIdSingleMockPhoto = specificPhotoId => ({
  photoId: specificPhotoId,
  blobUrl: 'MOCK_PHOTO_STRING',
});

export const multipleMockPhotos = [
  { ...singleMockPhoto, photoId: requiredPhotosPhotoIdList[0] },
  { ...singleMockPhoto, photoId: requiredPhotosPhotoIdList[1] },
  { ...singleMockPhoto, photoId: requiredPhotosPhotoIdList[2] },
  { ...singleMockPhoto, photoId: requiredPhotosPhotoIdList[3] },
  { ...singleMockPhoto, photoId: requiredPhotosPhotoIdList[4] },
  { ...singleMockPhoto, photoId: requiredPhotosPhotoIdList[5] },
];

export default {
  singleMockPhoto,
  multipleMockPhotos,
  photoIdSingleMockPhoto,
};
