import { fileUtils } from '@lmig-latam/adlib-ui';
import { isLocalhost } from '../config/environments';
import { getCookie, getCountryCode } from '../utils';
import { store } from '../utils/configureStore';
import {
  AUTH_TOKEN_COOKIE_NAME,
  EMAIL_TOKEN_COOKIE_NAME,
} from '../utils/constants';
import {
  checkForEmailToken,
  checkForTokens,
  InvalidStatusError,
  TokenError,
} from '../utils/customErrors';
import { logcodes, logger } from '../utils/logger';
import { ERROR_GENERIC } from '../utils/navigationConstants';
import { navigate } from '../utils/NavigationUtils';

const GET = 'GET';
const POST = 'POST';
const DELETE = 'DELETE';

const buildBody = (body = {}, method) => {
  if ([GET, DELETE].includes(method)) {
    return null;
  }

  return JSON.stringify({
    ...body,
    emailToken: getCookie(EMAIL_TOKEN_COOKIE_NAME),
    countryCode: body.countryCode
      ? body.countryCode.toLowerCase()
      : getCountryCode(),
  });
};

const getRequestHeaders = (extraHeaders = {}) => {
  const authToken = getCookie(AUTH_TOKEN_COOKIE_NAME);

  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: authToken,
  };
  return { ...headers, ...extraHeaders };
};

const buildQueryStringFromObject = queryObject => {
  const queryStrings = [];
  let queryString = '';

  Object.keys(queryObject).forEach(key => {
    if (queryObject[key]) {
      queryStrings.push(`${key}=${queryObject[key]}`);
    }
  });

  if (queryStrings.length > 0) {
    queryString += `?${queryStrings.join('&')}`;
  }

  return queryString;
};

// Only log Client error if it is not already being logged by the API
const shouldLogClientError = response => !response.code;

const buildFullPath = (path, extraParams = {}) => {
  const {
    settings: {
      environment: { appUrl },
    },
  } = store.getState();

  const queryObject = {
    ...extraParams,
    countryCode: getCountryCode(),
  };

  const fullPath = isLocalhost() ? `${appUrl}/api/${path}` : `/api/${path}`;

  const pathWithQueryParams = `${fullPath}${buildQueryStringFromObject(
    queryObject,
  )}`;

  return pathWithQueryParams;
};

const serviceSuccessHandler = async (
  response,
  isLoggingPath,
  fullPath,
  disableErrorHandling,
) => {
  // Get the raw text of the response
  let parsedResponse = await response.text();

  // If the raw text has a length, then parse it as JSON, otherwise assign an empty object
  parsedResponse = parsedResponse.length ? JSON.parse(parsedResponse) : {};

  if (!response.ok && !isLoggingPath) {
    if (shouldLogClientError(parsedResponse)) {
      logger.log(logcodes.CAAPI110, {
        path: fullPath,
        status: response.status,
      });
    }

    if (response.status === 401) {
      return Promise.reject(new TokenError(logcodes.CANAV120.code));
    }

    if (parsedResponse.data && parsedResponse.data.invalidStatus) {
      return Promise.reject(
        new InvalidStatusError(parsedResponse.data.invalidStatus),
      );
    }

    return disableErrorHandling
      ? Promise.reject(parsedResponse)
      : navigate(ERROR_GENERIC);
  }

  return Promise.resolve(parsedResponse);
};

const serviceFailureHandler = (
  error,
  isLoggingPath,
  fullPath,
  disableErrorHandling,
) => {
  const { message, stack } = error;
  if (!isLoggingPath) {
    logger.log(logcodes.CAAPI120, {
      path: fullPath,
      message,
      stack,
    });
  }

  return disableErrorHandling ? Promise.reject(error) : navigate(ERROR_GENERIC);
};

const callService = async (
  path,
  body,
  disableErrorHandling = false,
  method,
  headers,
  params,
) => {
  // Need to supress errors for the logging service to avoid endless loops
  const isLoggingPath = path === 'log';

  const fullPath = buildFullPath(path, params);

  try {
    const response = await fetch(fullPath, {
      method,
      credentials: 'same-origin',
      headers: getRequestHeaders(headers),
      body: buildBody(body, method),
    });

    return serviceSuccessHandler(
      response,
      isLoggingPath,
      fullPath,
      disableErrorHandling,
    );
  } catch (error) {
    return serviceFailureHandler(
      error,
      isLoggingPath,
      fullPath,
      disableErrorHandling,
    );
  }
};

const del = (path, disableErrorHandling, params) =>
  callService(path, null, disableErrorHandling, DELETE, null, params);

const post = (path, body, disableErrorHandling, headers) =>
  callService(path, body, disableErrorHandling, POST, headers);

const get = (path, disableErrorHandling) =>
  callService(path, null, disableErrorHandling, GET);

export const uploadPhoto = async ({ photoId, blobUrl }) => {
  checkForTokens();

  const data = {
    name: photoId,
    data: await fileUtils.getBlobFileFromUrl(blobUrl),
    emailToken: getCookie(EMAIL_TOKEN_COOKIE_NAME),
  };

  const formData = new FormData();

  Object.keys(data).forEach(key => {
    formData.append(key, data[key]);
  });

  const fullPath = buildFullPath('images');

  try {
    const response = await fetch(fullPath, {
      method: POST,
      credentials: 'same-origin',
      headers: {
        Authorization: getCookie(AUTH_TOKEN_COOKIE_NAME),
      },
      body: formData,
    });

    return serviceSuccessHandler(response, false, fullPath, true);
  } catch (error) {
    return serviceFailureHandler(error, false, fullPath, true);
  }
};

export const deletePhoto = photo => {
  checkForTokens();

  return del(`images/${getCookie(EMAIL_TOKEN_COOKIE_NAME)}/delete`, false, {
    name: photo,
  });
};

export const validateEmailToken = () => {
  checkForEmailToken();

  return post('emailtoken/validate', {}, true);
};

export const generateSmsConfirmationCode = (disableErrorHandling = false) => {
  checkForEmailToken();

  return post('code/sms', {}, disableErrorHandling);
};

export const acceptTermsAndConditions = () => {
  checkForTokens();

  return post('terms/accept', {});
};

export const validateSmsConfirmationCode = smsConfirmationCode => {
  checkForEmailToken();

  const body = {
    smsCode: smsConfirmationCode,
  };

  return post('code/sms/validate', body, true);
};

export const logMessage = body => post('log', body, true);

export const finishProcess = () => {
  checkForTokens();

  return post('claim/finish', {}, true);
};

export const getRetakePhotos = () => {
  checkForTokens();

  return get(`images/${getCookie(EMAIL_TOKEN_COOKIE_NAME)}/retake`);
};

export const getResumePhotos = () => {
  checkForTokens();

  return get(`images/${getCookie(EMAIL_TOKEN_COOKIE_NAME)}/resume`);
};

export const fetchSaveDamageDescription = damageDescription =>
  post('claim/finish', { damageDescription }, true);

export const fetchRemoveDamageDescription = () =>
  post('claim/finish', { removeDescription: true }, true);

// If you're adding functions here, be sure to mock them in config/jest/setup.js
export default {
  acceptTermsAndConditions,
  finishProcess,
  generateSmsConfirmationCode,
  getRetakePhotos,
  getResumePhotos,
  logMessage,
  uploadPhoto,
  deletePhoto,
  validateEmailToken,
  validateSmsConfirmationCode,
  fetchSaveDamageDescription,
  fetchRemoveDamageDescription,
};
