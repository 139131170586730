import React from 'react';
import PropTypes from 'prop-types';
import { BodyText, Content, Disclaimer } from '@lmig/lmds-react';
import ReactHtmlParser from 'react-html-parser';
import { ProcessStepsList } from '..';
import './styles.scss';

const PhotoHubSection = ({
  title,
  subtitle,
  navigate,
  takenPhotos,
  photosConfig,
}) => (
  <Content>
    {!!title && (
      <BodyText className="photo-hub-section-title">{title}</BodyText>
    )}

    {!!subtitle && (
      <Disclaimer>
        <p className="damage-description-subtitle">
          {ReactHtmlParser(subtitle)}
        </p>
      </Disclaimer>
    )}

    <ProcessStepsList
      navigate={navigate}
      photos={takenPhotos}
      requiredPhotos={photosConfig}
    />
  </Content>
);

PhotoHubSection.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  navigate: PropTypes.func.isRequired,
  takenPhotos: PropTypes.arrayOf(PropTypes.any).isRequired,
  photosConfig: PropTypes.arrayOf(PropTypes.any).isRequired,
};

PhotoHubSection.defaultProps = {
  title: '',
  subtitle: '',
};

export default PhotoHubSection;
