import {
  BodyText,
  Button,
  Caption,
  Content,
  GridCol,
  GridRow,
  Heading,
  IconDocumentMultiple,
} from '@lmig/lmds-react';
import React, { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { TermsConditionsActions } from '../../actions';
import { AppFooter, AppHeader, CircleImage, Modal } from '../../components';
import localizedStrings from '../../utils/localizedStrings';
import './styles.scss';

const {
  TERMS_CONDITIONS_TITLE,
  TERMS_CONDITIONS_SUBTITLE,
  TERMS_CONDITIONS_SHOW_MODAL_BUTTON,
  TERMS_CONDITIONS_DISCLAIMER,
  TERMS_CONDITIONS_CONTINUE_BUTTON,
  TERMS_CONDITIONS_LOADING_TEXT,
  TERMS_CONDITIONS_MODAL_TITLE,
  TERMS_CONDITIONS_MODAL_CONTENT,
  TERMS_CONDITIONS_MODAL_PRIMARY_BUTTON_LABEL,
} = localizedStrings;

const TermsConditions = ({
  termsConditionsActions: { acceptTermsAndConditions },
}) => {
  const [
    termsConditionModalDisplayed,
    setTermsConditionModalDisplayed,
  ] = useState(false);

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const showTermsConditionsModal = () => {
    setTermsConditionModalDisplayed(true);
  };

  const hideTermsConditionsModal = () => {
    setTermsConditionModalDisplayed(false);
  };

  const submit = async () => {
    setDisableSubmitButton(true);
    await acceptTermsAndConditions();
  };

  const renderFooterContents = () => (
    <GridRow className="terms-conditions-footer">
      <GridCol className="align-center">
        <Button type="button" onClick={submit} disabled={disableSubmitButton}>
          {!disableSubmitButton
            ? TERMS_CONDITIONS_CONTINUE_BUTTON
            : TERMS_CONDITIONS_LOADING_TEXT}
        </Button>
      </GridCol>
    </GridRow>
  );

  return (
    <div className="adcl-screen">
      <Modal
        isOpen={termsConditionModalDisplayed}
        secondaryButtonLabel={TERMS_CONDITIONS_MODAL_PRIMARY_BUTTON_LABEL}
        secondaryButtonHandler={hideTermsConditionsModal}
        closeButtonHandler={hideTermsConditionsModal}
        backButtonHandler={hideTermsConditionsModal}
      >
        <div className="terms-conditions-modal-body">
          <CircleImage>
            <IconDocumentMultiple sizing="auto" />
          </CircleImage>
          <Heading type="h3" className="auto-left-right-margin">
            {TERMS_CONDITIONS_MODAL_TITLE}
          </Heading>
          <BodyText className="terms-conditions-modal-body-text">
            {ReactHtmlParser(TERMS_CONDITIONS_MODAL_CONTENT)}
          </BodyText>
        </div>
      </Modal>

      <AppHeader displaySubtitle />
      <Content className="adcl-content">
        <CircleImage>
          <IconDocumentMultiple sizing="auto" />
        </CircleImage>
        <Heading type="h3-light" className="auto-left-right-margin">
          {TERMS_CONDITIONS_TITLE}
        </Heading>
        <Heading
          type="h4-light"
          className="terms-conditions-subtitle auto-left-right-margin"
        >
          {TERMS_CONDITIONS_SUBTITLE}
        </Heading>
        <Button
          type="button"
          className="terms-conditions-show-modal-button"
          onClick={showTermsConditionsModal}
        >
          {TERMS_CONDITIONS_SHOW_MODAL_BUTTON}
          <span className="terms-conditions-show-modal-button-icon">
            &rang;
          </span>
        </Button>
        <Caption className="terms-conditions-disclaimer">
          {TERMS_CONDITIONS_DISCLAIMER}
        </Caption>
        {renderFooterContents()}
      </Content>
      <AppFooter />
    </div>
  );
};

TermsConditions.propTypes = {
  termsConditionsActions: TermsConditionsActions.PropertyTypes.isRequired,
};

const mapDispatchToProps = dispatch => ({
  termsConditionsActions: bindActionCreators(TermsConditionsActions, dispatch),
});

export default connect(null, mapDispatchToProps)(TermsConditions);
