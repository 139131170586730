import { GridCol, GridRow } from '@lmig/lmds-react';
import PropTypes from 'prop-types';
import React from 'react';
import { PhotoPreviewPanel } from '..';
import './styles.scss';

const renderPhotoPreviewPanel = (photo, navigate, previewPhoto, disabled) => (
  <GridRow key={photo.photoId}>
    <GridCol>
      <PhotoPreviewPanel
        {...photo}
        disabled={disabled}
        navigate={navigate}
        previewPhoto={previewPhoto}
      />
    </GridCol>
  </GridRow>
);

const ProcessStepsList = ({ navigate, photos, requiredPhotos }) => (
  <div className="process-steps-list-container">
    {requiredPhotos.map((requiredPhoto, index) => {
      const photo = photos.filter(
        element => element.photoId === requiredPhoto.photoId,
      )[0];
      const previewPhoto = photo ? photo.blobUrl : '';

      // The PhotoPreviewPanel will be disabled if:
      // - It is not the first panel in the list
      // AND
      // - The previous panel doesn't have a photo yet
      const disabled = index > 0 && !photos[index - 1];

      return renderPhotoPreviewPanel(
        requiredPhoto,
        navigate,
        previewPhoto,
        disabled,
      );
    })}
  </div>
);

ProcessStepsList.propTypes = {
  navigate: PropTypes.func.isRequired,
  photos: PropTypes.arrayOf(PropTypes.any).isRequired,
  requiredPhotos: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default ProcessStepsList;
