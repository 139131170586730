import React, { useRef } from 'react';
import { useFormState } from 'informed';
import PropTypes from 'prop-types';
import { utils } from '@lmig-latam/adlib-ui';
import { Disclaimer, Caption, Heading, IconAlert } from '@lmig/lmds-react';
import { CapturePhoto, Modal, InformedAlphanumericInput } from '..';
import localizedStrings from '../../utils/localizedStrings';

const {
  PHOTO_HUB_OPTIONAL_SECTION_MODAL_TITLE,
  PHOTO_HUB_OPTIONAL_SECTION_MODAL_INPUT_DESCRIPTION,
  PHOTO_HUB_OPTIONAL_SECTION_MODAL_INPUT_HINT,
  PHOTO_HUB_OPTIONAL_SECTION_MODAL_PRIMARY_BUTTON_LABEL,
  PHOTO_HUB_OPTIONAL_SECTION_MODAL_SECONDARY_BUTTON_LABEL,
} = localizedStrings;

const dataModal = {
  title: PHOTO_HUB_OPTIONAL_SECTION_MODAL_TITLE,
  inputDescription: PHOTO_HUB_OPTIONAL_SECTION_MODAL_INPUT_DESCRIPTION,
  inputHint: PHOTO_HUB_OPTIONAL_SECTION_MODAL_INPUT_HINT,
  primaryButton: PHOTO_HUB_OPTIONAL_SECTION_MODAL_PRIMARY_BUTTON_LABEL,
  secundaryButton: PHOTO_HUB_OPTIONAL_SECTION_MODAL_SECONDARY_BUTTON_LABEL,
};

function ModalOptionalSection({
  optionalModalDisplayed,
  hideOptionalPhotoModal,
  showInlineLoader,
}) {
  const {
    title,
    inputDescription,
    inputHint,
    primaryButton,
    secundaryButton,
  } = dataModal;

  const formState = useFormState();
  const capturePhotoRef = useRef();

  const { formatString } = utils;

  const { VALIDATION_FIELD_MAX, VALIDATION_FIELD_EMPTY } = localizedStrings;

  const disableContinueButton =
    !formState.values.optionalPhotoName || formState.errors.optionalPhotoName;

  const handlerPrimaryButtonModal = async () => {
    capturePhotoRef.current.click();
  };

  const validateOptionalPhotoName = fieldValue => {
    let errorMessage; // Should default to undefined if validation passes

    if (!fieldValue) {
      errorMessage = VALIDATION_FIELD_EMPTY;
    } else if (fieldValue.length > 30) {
      errorMessage = `${formatString(VALIDATION_FIELD_MAX, {
        maxLength: '30',
      })}`;
    }
    return errorMessage;
  };

  return (
    <Modal
      isOpen={optionalModalDisplayed}
      closeButtonHandler={hideOptionalPhotoModal}
      primaryButtonLabel={primaryButton}
      primaryButtonHandler={handlerPrimaryButtonModal}
      primaryButtonDisabled={disableContinueButton}
      secondaryButtonLabel={secundaryButton}
      secondaryButtonHandler={hideOptionalPhotoModal}
      id="optional-photo-modal"
    >
      <div className="pad-top-and-bottom optional-photos-section-modal-body">
        <CapturePhoto
          ref={capturePhotoRef}
          photoId={formState.values.optionalPhotoName}
          showInlineLoader={showInlineLoader}
          title={formState.values.optionalPhotoName}
          hideOptionalPhotoModal={hideOptionalPhotoModal}
        />

        <Heading
          type="h4-light"
          className="optional-photos-section-modal-title align-center"
        >
          {title}
        </Heading>

        <InformedAlphanumericInput
          id="optionalPhotoName"
          field="optionalPhotoName"
          labelVisual={inputDescription}
          validate={validateOptionalPhotoName}
          initialValue=""
          validateOnChange
          validateOnBlur
        />

        <Disclaimer className="gray-text float-left">{inputHint}</Disclaimer>

        {formState.errors.optionalPhotoName && (
          <Caption className="error-message optional-photo-modal-error-message">
            <IconAlert />
            {formState.errors.optionalPhotoName}
          </Caption>
        )}
      </div>
    </Modal>
  );
}

ModalOptionalSection.propTypes = {
  dataModal: PropTypes.shape({
    key: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    inputDescription: PropTypes.string.isRequired,
    inputHint: PropTypes.string.isRequired,
    primaryButton: PropTypes.string.isRequired,
    secundaryButton: PropTypes.string.isRequired,
    isTakePhoto: PropTypes.bool.isRequired,
  }).isRequired,
  optionalModalDisplayed: PropTypes.bool.isRequired,
  hideOptionalPhotoModal: PropTypes.func.isRequired,
  showInlineLoader: PropTypes.func.isRequired,
};

export default ModalOptionalSection;
