import {
  DELETE_DAMAGE_DESCRIPTION,
  SAVE_DAMAGE_DESCRIPTION,
} from './ActionTypes';

import {
  fetchRemoveDamageDescription,
  fetchSaveDamageDescription,
} from '../api';

const addDamageDescription = description => async dispatch => {
  await fetchSaveDamageDescription(description);
  dispatch({
    type: SAVE_DAMAGE_DESCRIPTION,
  });
};

const deleteDamageDescription = () => async dispatch => {
  await fetchRemoveDamageDescription();
  dispatch({
    type: DELETE_DAMAGE_DESCRIPTION,
  });
};

export default { addDamageDescription, deleteDamageDescription };
