import React from 'react';
import PropTypes from 'prop-types';
import { Disclaimer, GridCol, GridRow, IconAdd } from '@lmig/lmds-react';
import { STATE_DAMAGE_DESCRIPTION_SECTION } from './DamageDescriptionSection';
import { IconButton } from '..';
import './styles.scss';

function NotWriterSection({ subtitle, setStatus }) {
  return (
    <GridRow vAlign="middle">
      <GridCol>
        <Disclaimer className="gray-text float-left">{subtitle}</Disclaimer>
      </GridCol>

      <GridCol base={2}>
        <IconButton
          IconComponent={IconAdd}
          onClick={() => setStatus(STATE_DAMAGE_DESCRIPTION_SECTION.IN_WRITE)}
        />
      </GridCol>
    </GridRow>
  );
}

NotWriterSection.propTypes = {
  subtitle: PropTypes.string.isRequired,
  setStatus: PropTypes.func.isRequired,
};

export default NotWriterSection;
