import PropTypes from 'prop-types';
import { fileUtils } from '@lmig-latam/adlib-ui';
import { finishProcess, uploadPhoto, deletePhoto } from '../api';
import localizedStrings from '../utils/localizedStrings';
import { logcodes, logger } from '../utils/logger';
import { PROCESS_COMPLETE, ERROR_GENERIC } from '../utils/navigationConstants';
import { navigate } from '../utils/NavigationUtils';
import { DELETE_PHOTO, HIDE_APP_LOADER, SHOW_APP_LOADER } from './ActionTypes';
import { store } from '../utils/configureStore';
import { TakePhotoActions } from '.';
import {
  TokenError,
  InvalidStatusError,
  handleTokenMissingError,
  handleInvalidStatusError,
} from '../utils/customErrors';

const { deleteBlobUrl } = fileUtils;
const { PHOTO_HUB_FINISH_PROGRESS } = localizedStrings;

export default {
  PropertyTypes: PropTypes.shape({
    finishProcess: PropTypes.func.isRequired,
    deletePhoto: PropTypes.func.isRequired,
    uploadPhoto: PropTypes.func.isRequired,
  }),

  deletePhoto(photoId) {
    return async dispatch => {
      const {
        photos: { requiredPhotosTaken, optionalPhotosTaken },
      } = store.getState();

      const allPhotos = [...requiredPhotosTaken, ...optionalPhotosTaken];

      const fileType = allPhotos
        .filter(photo => photo.photoId === photoId)
        .map(item => item.fileType)[0];

      const photoName = `${photoId}.${fileType}`;

      // Delete server side version of photo
      try {
        await deletePhoto(photoName);

        // Delete local version of photo
        dispatch({
          type: DELETE_PHOTO,
          photoId,
        });
      } catch (error) {
        if (error instanceof TokenError) {
          handleTokenMissingError(error);
        } else if (error instanceof InvalidStatusError) {
          handleInvalidStatusError(error);
        } else {
          navigate(ERROR_GENERIC);
        }
      }
    };
  },

  uploadPhoto(photoToUpload, photoToDisplay) {
    return async dispatch => {
      try {
        logger.log(logcodes.CAUPL130, {
          name: photoToUpload.photoId,
        });
        await uploadPhoto(photoToUpload);
        deleteBlobUrl(photoToUpload.blobUrl);
        await dispatch(TakePhotoActions.storePhoto(photoToDisplay));
      } catch (error) {
        // Delete local version of photo
        dispatch({
          type: DELETE_PHOTO,
          photoId: photoToDisplay.photoId,
        });

        logger.log(logcodes.CAUPL120, {
          name: photoToUpload.photoId,
          error: error.message,
          stack: error.stack,
        });

        if (error instanceof TokenError) {
          handleTokenMissingError(error);
        } else if (error instanceof InvalidStatusError) {
          handleInvalidStatusError(error);
        } else {
          throw error;
        }
      }
    };
  },

  finishProcess() {
    return async dispatch => {
      dispatch({
        type: SHOW_APP_LOADER,
        translucent: true,
        message: PHOTO_HUB_FINISH_PROGRESS,
      });

      try {
        await finishProcess();
      } catch (error) {
        logger.log(logcodes.CAUPL140, { error });

        if (error instanceof TokenError) {
          handleTokenMissingError(error);
        } else if (error instanceof InvalidStatusError) {
          handleInvalidStatusError(error);
        } else {
          navigate(ERROR_GENERIC);
        }
        return;
      } finally {
        dispatch({
          type: HIDE_APP_LOADER,
        });
      }

      logger.log(logcodes.CAUPL110);

      window.location.href = `${PROCESS_COMPLETE}`;
    };
  },
};
