import { Heading } from '@lmig/lmds-react';
import PropTypes from 'prop-types';
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { Modal } from '..';
import localizedStrings from '../../utils/localizedStrings';
import './styles.scss';

const {
  TAKE_PHOTO_MODAL_TITLE,
  TAKE_PHOTO_MODAL_IMAGE_ALT_TEXT,
  TAKE_PHOTO_MODAL_PRIMARY_BUTTON_LABEL,
} = localizedStrings;

const HintsModal = ({ isOpen, onClose, hints, onContinue }) => {
  const settings = {
    arrows: true,
    dots: true,
    infinite: false,
    className: 'hints-modal-slider',
  };

  return (
    <Modal
      isOpen={isOpen}
      closeButtonHandler={onClose}
      secondaryButtonLabel={TAKE_PHOTO_MODAL_PRIMARY_BUTTON_LABEL}
      secondaryButtonHandler={onContinue}
    >
      <Heading type="h3-light" className="auto-left-right-margin align-center">
        {TAKE_PHOTO_MODAL_TITLE}
      </Heading>
      <Slider {...settings}>
        {hints.images.map(image => (
          <img
            className="hints-modal-image"
            src={image}
            key={image}
            alt={TAKE_PHOTO_MODAL_IMAGE_ALT_TEXT}
          />
        ))}
      </Slider>
      <Heading
        type="h4-light"
        className="hints-modal-heading auto-left-right-margin"
      >
        {hints.text}
      </Heading>
    </Modal>
  );
};

HintsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  hints: PropTypes.shape({
    text: PropTypes.string.isRequired,
    images: PropTypes.array.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
};

export default HintsModal;
