import {
  BodyText,
  Disclaimer,
  GridCol,
  GridRow,
  Heading,
  IconAdd,
  IconCamera,
  IconCheckmark,
  IconTrash,
} from '@lmig/lmds-react';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  CapturePhoto,
  CircleImage,
  HintsModal,
  IconButton,
  InlineLoader,
  Modal,
  Panel,
} from '..';
import { getRequiredPhoto } from '../../config/requiredPhotos';
import localizedStrings from '../../utils/localizedStrings';
import IconDeletePhoto from './deletephoto.svg';
import IconRetakePhoto from './retakephoto.svg';
import './styles.scss';

const {
  PHOTO_HUB_STEP_PANEL_IMAGE_ALT_TEXT,
  PHOTO_HUB_RETAKE_PHOTO_IMAGE_ALT_TEXT,
  PHOTO_HUB_ACCESSORIES_DELETE_TILE,
  PHOTO_HUB_ACCESSORIES_DELETE_PRIMARY_BUTTON_LABEL,
  PHOTO_HUB_ACCESSORIES_DELETE_SECONDARY_BUTTON_LABEL,
  PHOTO_HUB_ACCESSORIES_DELETE_IMAGE_ALT_TEXT,
  TAKE_PHOTO_RETAKE_MODAL_TITLE,
  TAKE_PHOTO_RETAKE_MODAL_SUBTITLE,
  TAKE_PHOTO_RETAKE_MODAL_PRIMARY_BUTTON_LABEL,
  TAKE_PHOTO_RETAKE_MODAL_SECONDARY_BUTTON_LABEL,
} = localizedStrings;

const PhotoPreviewPanel = ({
  displayHints,
  photoId,
  deletePhoto,
  title,
  body,
  previewPhoto,
  disabled,
  displayRetakePhotoButton,
}) => {
  // This will be used to get a handle on the <input> element inside CapturePhoto
  const capturePhotoRef = useRef();

  const [deleteModalDisplayed, setDeleteModalDisplayed] = useState(false);
  const [retakePhotoModalDisplayed, setRetakePhotoModalDisplayed] = useState(
    false,
  );
  const [hintsModalDisplayed, setHintsModalDisplayed] = useState(false);
  const [inlineLoaderDisplayed, setInlineLoaderDisplayed] = useState(false);

  const hideAllModals = () => {
    setDeleteModalDisplayed(false);
    setRetakePhotoModalDisplayed(false);
    setHintsModalDisplayed(false);
  };

  const showDeleteModal = () => {
    setDeleteModalDisplayed(true);
    setRetakePhotoModalDisplayed(false);
    setHintsModalDisplayed(false);
  };

  const hideDeleteModal = () => {
    setDeleteModalDisplayed(false);
  };

  const showRetakePhotoModal = () => {
    setDeleteModalDisplayed(false);
    setRetakePhotoModalDisplayed(true);
    setHintsModalDisplayed(false);
  };

  const hideRetakePhotoModal = () => {
    setRetakePhotoModalDisplayed(false);
  };

  const showInlineLoader = flag => {
    setInlineLoaderDisplayed(flag);
  };

  const showHintsModal = () => {
    setDeleteModalDisplayed(false);
    setRetakePhotoModalDisplayed(false);
    setHintsModalDisplayed(true);
  };

  const hideHintsModal = () => {
    setHintsModalDisplayed(false);
  };

  const openCapturePhotoComponent = () => {
    hideAllModals();
    capturePhotoRef.current.click();
  };

  const getPhotoFromPhotoGroup = () => {
    if (getRequiredPhoto(photoId)) {
      return getRequiredPhoto(photoId);
    }

    return undefined;
  };

  const startCapturePhotoFlow = () => {
    const photo = getPhotoFromPhotoGroup();

    const hintsAvailable = photo ? !!photo.hints : false;

    if (displayHints && hintsAvailable) {
      showHintsModal();
    } else {
      openCapturePhotoComponent();
    }
  };

  const deletePhotoHandler = () => {
    deletePhoto(photoId);
  };

  const renderDeletePhotoModal = () => (
    <Modal
      isOpen={deleteModalDisplayed}
      backButtonHandler={hideDeleteModal}
      primaryButtonLabel={PHOTO_HUB_ACCESSORIES_DELETE_PRIMARY_BUTTON_LABEL}
      primaryButtonHandler={deletePhotoHandler}
      secondaryButtonLabel={PHOTO_HUB_ACCESSORIES_DELETE_SECONDARY_BUTTON_LABEL}
      secondaryButtonHandler={hideDeleteModal}
    >
      <Heading type="h3-light" className="align-center">
        {PHOTO_HUB_ACCESSORIES_DELETE_TILE}
      </Heading>
      <CircleImage>
        <img
          src={IconDeletePhoto}
          alt={PHOTO_HUB_ACCESSORIES_DELETE_IMAGE_ALT_TEXT}
        />
      </CircleImage>
    </Modal>
  );

  const renderRetakePhotoModal = () => (
    <Modal
      isOpen={retakePhotoModalDisplayed}
      backButtonHandler={hideRetakePhotoModal}
      primaryButtonLabel={TAKE_PHOTO_RETAKE_MODAL_PRIMARY_BUTTON_LABEL}
      primaryButtonHandler={startCapturePhotoFlow}
      secondaryButtonLabel={TAKE_PHOTO_RETAKE_MODAL_SECONDARY_BUTTON_LABEL}
      secondaryButtonHandler={hideRetakePhotoModal}
    >
      <Heading type="h3-light" className="align-center auto-left-right-margin">
        {TAKE_PHOTO_RETAKE_MODAL_TITLE}
      </Heading>
      <Heading type="h4-light" className="auto-left-right-margin">
        {TAKE_PHOTO_RETAKE_MODAL_SUBTITLE}
      </Heading>
      <CircleImage className="retake-photo-modal-image">
        <img
          src={IconRetakePhoto}
          alt={PHOTO_HUB_RETAKE_PHOTO_IMAGE_ALT_TEXT}
        />
      </CircleImage>
    </Modal>
  );

  const renderPreviewPanelBody = () => (
    <div>
      {renderDeletePhotoModal()}
      {renderRetakePhotoModal()}
      <Panel className="panel-photo-preview">
        <GridRow className="panel-photo-preview" vAlign="middle">
          <GridCol base={4}>
            <BodyText type="lead">{title}</BodyText>
          </GridCol>
          <GridCol>
            <IconButton
              removeShadow
              background="round"
              IconComponent={IconCheckmark}
            />
          </GridCol>
          {deletePhoto && (
            <GridCol>
              <IconButton
                transparent
                color="default"
                background="round"
                IconComponent={IconTrash}
                onClick={showDeleteModal}
              />
            </GridCol>
          )}
          {displayRetakePhotoButton && (
            <GridCol>
              <IconButton
                simple
                color="default"
                IconComponent={IconCamera}
                onClick={showRetakePhotoModal}
              />
            </GridCol>
          )}
        </GridRow>
        <GridRow>
          <GridCol>
            <div className="photo-loader">
              <InlineLoader
                isLoading={inlineLoaderDisplayed}
                showAnimation={false}
              />
            </div>
          </GridCol>
        </GridRow>
        <div className="preview-image-container">
          <img src={previewPhoto} alt={PHOTO_HUB_STEP_PANEL_IMAGE_ALT_TEXT} />
        </div>
      </Panel>
    </div>
  );

  const renderDefaultPanelBody = () => (
    <Panel disabled={disabled}>
      <GridRow>
        <GridCol>
          <BodyText className="card-container" type="lead">
            {title}
          </BodyText>
        </GridCol>
      </GridRow>
      <GridRow>
        <GridCol>
          <Disclaimer className="gray-text float-left">{body}</Disclaimer>
        </GridCol>
        <GridCol base={3}>
          {disabled ? (
            <IconButton
              disabled
              color="default"
              IconComponent={IconAdd}
              className="float-right"
            />
          ) : (
            <IconButton
              IconComponent={IconCamera}
              onClick={startCapturePhotoFlow}
              className="float-right"
            />
          )}
        </GridCol>
      </GridRow>
      <GridRow>
        <GridCol>
          <div className="photo-loader">
            <InlineLoader
              isLoading={inlineLoaderDisplayed}
              showAnimation={false}
            />
          </div>
        </GridCol>
      </GridRow>
    </Panel>
  );

  let renderBody;

  if (previewPhoto) {
    renderBody = renderPreviewPanelBody();
  } else {
    renderBody = renderDefaultPanelBody();
  }

  const photoData = getPhotoFromPhotoGroup();

  const shouldDisplayHints = displayHints && photoData && photoData.hints;

  return (
    <div>
      <CapturePhoto
        ref={capturePhotoRef}
        photoId={photoId}
        showInlineLoader={showInlineLoader}
      />
      {shouldDisplayHints && (
        <HintsModal
          isOpen={hintsModalDisplayed}
          hints={photoData.hints}
          onClose={hideHintsModal}
          onContinue={openCapturePhotoComponent}
        />
      )}
      {renderBody}
    </div>
  );
};

PhotoPreviewPanel.propTypes = {
  photoId: PropTypes.string.isRequired,
  title: PropTypes.string,
  body: PropTypes.string,
  deletePhoto: PropTypes.func,
  previewPhoto: PropTypes.string,
  disabled: PropTypes.bool,
  displayHints: PropTypes.bool,
  displayRetakePhotoButton: PropTypes.bool,
};

PhotoPreviewPanel.defaultProps = {
  title: '',
  body: '',
  previewPhoto: '',
  disabled: false,
  deletePhoto: null,
  displayHints: false,
  displayRetakePhotoButton: true,
};

const mapStateToProps = ({ settings: { displayHints } }) => ({
  displayHints,
});

export default connect(mapStateToProps, null)(PhotoPreviewPanel);
