import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

function Textarea({ rows, placeholder, value, maxLength, onChange }) {
  return (
    <textarea
      rows={rows}
      value={value}
      type="text"
      maxLength={maxLength}
      placeholder={placeholder}
      onChange={e => onChange(e.target.value)}
      className="text-area-damage-description"
    />
  );
}

Textarea.propTypes = {
  rows: PropTypes.number.isRequired,
  maxLength: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Textarea;
