import PropTypes from 'prop-types';
import React from 'react';
import { BodyText } from '@lmig/lmds-react';
import localizedStrings from '../../utils/localizedStrings';
import { LoadingAnimation } from '..';
import './styles.scss';

const { INLINE_LOADING_TEXT } = localizedStrings;

const InlineLoader = ({ isLoading, showAnimation, message }) =>
  isLoading && (
    <div className="inline-loader">
      {showAnimation ? (
        <LoadingAnimation />
      ) : (
        <div className="inline-loader-text-container">
          <BodyText>{message}</BodyText>
        </div>
      )}
    </div>
  );

InlineLoader.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  showAnimation: PropTypes.bool,
  message: PropTypes.string,
};

InlineLoader.defaultProps = {
  showAnimation: true,
  message: INLINE_LOADING_TEXT,
};

export default InlineLoader;
