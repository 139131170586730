import PropTypes from 'prop-types';
import { STORE_PHOTO } from './ActionTypes';

export default {
  PropertyTypes: PropTypes.shape({
    storePhoto: PropTypes.func.isRequired,
  }),

  storePhoto(photo) {
    return async dispatch => {
      dispatch({
        type: STORE_PHOTO,
        photo,
      });
    };
  },
};
