import React from 'react';
import PropTypes from 'prop-types';
import { Disclaimer, IconAlert, Button } from '@lmig/lmds-react';
import { Modal, CircleImage } from '..';
import localizedStrings from '../../utils/localizedStrings';

const {
  PHOTO_HUB_OPTIONAL_DESCRIPTION_DAMAGE_SECTION_MODAL_MESSAGE_TITLE,
  PHOTO_HUB_OPTIONAL_DESCRIPTION_DAMAGE_SECTION_MODAL_SUBMESSAGE,
  PHOTO_HUB_OPTIONAL_DESCRIPTION_DAMAGE_SECTION_EDIT_LABEL_BUTTON,
  PHOTO_HUB_OPTIONAL_DESCRIPTION_DAMAGE_SECTION_DELETE_LABEL_BUTTON,
  PHOTO_HUB_OPTIONAL_DESCRIPTION_DAMAGE_SECTION_CANCEL_LABEL_BUTTON,
} = localizedStrings;

function ModalDamageDescription({
  onDelete,
  onEdit,
  isOpenModal,
  onCloseModal,
}) {
  const infoModal = {
    message: PHOTO_HUB_OPTIONAL_DESCRIPTION_DAMAGE_SECTION_MODAL_MESSAGE_TITLE,
    subMessage: PHOTO_HUB_OPTIONAL_DESCRIPTION_DAMAGE_SECTION_MODAL_SUBMESSAGE,
    primaryButtonEdit: PHOTO_HUB_OPTIONAL_DESCRIPTION_DAMAGE_SECTION_EDIT_LABEL_BUTTON,
    secundaryButtonDelete: PHOTO_HUB_OPTIONAL_DESCRIPTION_DAMAGE_SECTION_DELETE_LABEL_BUTTON,
    cancelButton: PHOTO_HUB_OPTIONAL_DESCRIPTION_DAMAGE_SECTION_CANCEL_LABEL_BUTTON,
  };

  const disabledPrimaryButton = () => false;

  return (
    <Modal
      isOpen={isOpenModal}
      closeButtonHandler={onCloseModal}
      primaryButtonDisabled={disabledPrimaryButton()}
      secondaryButtonLabel={infoModal.secundaryButton}
      id="optional-photo-modal"
    >
      <div className="pad-top-and-bottom optional-photos-section-modal-body">
        <div className="icon-alert-delete-description">
          <CircleImage size="small">
            <IconAlert sizing="auto" />
          </CircleImage>
        </div>

        <p className="message-title-delete-description">{infoModal.message}</p>

        <Disclaimer className="submessage-delete-description">
          {infoModal.subMessage}
        </Disclaimer>

        <div className="align-center modal-primary-button save-description-button">
          <Button type="button" level="primary" onClick={() => onEdit()}>
            {infoModal.primaryButtonEdit}
          </Button>
        </div>

        <div className="align-center modal-secondary-button save-description-button">
          <Button type="button" level="secondary" onClick={() => onDelete()}>
            {infoModal.secundaryButtonDelete}
          </Button>
        </div>

        <div className="align-center modal-secondary-button save-description-button">
          <button
            type="button"
            className="delete-button"
            onClick={() => onCloseModal()}
          >
            {infoModal.cancelButton}
          </button>
        </div>
      </div>
    </Modal>
  );
}

ModalDamageDescription.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  isOpenModal: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default ModalDamageDescription;
