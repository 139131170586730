import PropTypes from 'prop-types';
import { acceptTermsAndConditions } from '../api';
import {
  handleInvalidStatusError,
  handleTokenMissingError,
  InvalidStatusError,
  TokenError,
} from '../utils/customErrors';
import { ERROR_GENERIC, PHOTO_HUB } from '../utils/navigationConstants';
import { navigate } from '../utils/NavigationUtils';

export default {
  PropertyTypes: PropTypes.shape({
    acceptTermsAndConditions: PropTypes.func.isRequired,
  }),

  acceptTermsAndConditions() {
    return async () => {
      try {
        await acceptTermsAndConditions();
        navigate(PHOTO_HUB);
      } catch (error) {
        if (error instanceof TokenError) {
          handleTokenMissingError(error);
        } else if (error instanceof InvalidStatusError) {
          handleInvalidStatusError(error);
        } else {
          navigate(ERROR_GENERIC);
        }
      }
    };
  },
};
