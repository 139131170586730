import PropTypes from 'prop-types';
import { STATUS_TYPES } from '@lmig-latam/adcl-common-lib/constants';
import {
  generateSmsConfirmationCode,
  getRetakePhotos,
  getResumePhotos,
  validateEmailToken,
  validateSmsConfirmationCode,
} from '../api';
import { deleteCookie, setCookie } from '../utils';
import { store } from '../utils/configureStore';
import {
  AUTH_TOKEN_COOKIE_NAME,
  RETAKE_DATA_COOKIE_NAME,
  RESUME_DATA_COOKIE_NAME,
  TOKEN_TIMEOUT,
} from '../utils/constants';
import {
  handleInvalidStatusError,
  handleTokenMissingError,
  InvalidStatusError,
  TokenError,
} from '../utils/customErrors';
import { ERROR_GENERIC } from '../utils/navigationConstants';
import { navigate } from '../utils/NavigationUtils';
import {
  HIDE_APP_LOADER,
  UPDATE_USER_DETAILS,
  UPDATE_RETAKE,
  UPDATE_RESUME,
} from './ActionTypes';

export default {
  PropertyTypes: PropTypes.shape({
    validateEmailTokenAndGenerateSmsConfirmationCode: PropTypes.func.isRequired,
    validateSmsConfirmationCode: PropTypes.func.isRequired,
    resendSmsConfirmationCode: PropTypes.func.isRequired,
  }),

  validateEmailTokenAndGenerateSmsConfirmationCode() {
    let userClaimStatus;
    let userDamageDescription;

    return async dispatch => {
      try {
        const { status, damageDescription } = await validateEmailToken();

        userClaimStatus = status;
        userDamageDescription = damageDescription;
      } catch (error) {
        if (error instanceof TokenError) {
          handleTokenMissingError(error);
        } else if (error instanceof InvalidStatusError) {
          handleInvalidStatusError(error);
        } else {
          // TODO build proper messaging for the customer rather than a generic page
          navigate(ERROR_GENERIC);
        }
        return;
      }

      const { maskedCellPhoneNumber } = await generateSmsConfirmationCode();

      dispatch({
        type: UPDATE_USER_DETAILS,
        value: {
          maskedCellPhoneNumber,
          userClaimStatus,
          userDamageDescription,
        },
      });

      dispatch({
        type: HIDE_APP_LOADER,
      });
    };
  },

  validateSmsConfirmationCode(smsConfirmationCode) {
    const {
      STATUS_CUSTOMER_IN_PROGRESS,
      STATUS_CUSTOMER_RETAKE,
    } = STATUS_TYPES;

    return async dispatch => {
      let validateSmsResponse;

      try {
        validateSmsResponse = await validateSmsConfirmationCode(
          smsConfirmationCode,
        );
      } catch (error) {
        if (error instanceof TokenError) {
          handleTokenMissingError(error);
        } else if (error instanceof InvalidStatusError) {
          handleInvalidStatusError(error);
        } else {
          throw error;
        }
      }

      const { authorizationToken } = validateSmsResponse;

      setCookie(AUTH_TOKEN_COOKIE_NAME, authorizationToken, TOKEN_TIMEOUT);

      const {
        user: { userClaimStatus },
      } = store.getState();

      if (userClaimStatus === STATUS_CUSTOMER_RETAKE) {
        const retakeConfig = await getRetakePhotos();

        const stringifiedRetakeConfig = JSON.stringify(retakeConfig);

        // Remove/delete RESUME Cookie and State
        deleteCookie(RESUME_DATA_COOKIE_NAME);

        dispatch({
          type: UPDATE_RESUME,
          value: undefined,
        });

        // Add/Update RETAKE Cookie and State
        setCookie(
          RETAKE_DATA_COOKIE_NAME,
          stringifiedRetakeConfig,
          TOKEN_TIMEOUT,
        );

        dispatch({
          type: UPDATE_RETAKE,
          value: retakeConfig,
        });
      }

      if (userClaimStatus === STATUS_CUSTOMER_IN_PROGRESS) {
        const resumeConfig = await getResumePhotos();

        const stringifiedResumeConfig = JSON.stringify(resumeConfig);

        // Remove/delete RETAKE Cookie and State
        deleteCookie(RETAKE_DATA_COOKIE_NAME);

        dispatch({
          type: UPDATE_RETAKE,
          value: undefined,
        });

        // Add/Update RESUME Cookie and State
        setCookie(
          RESUME_DATA_COOKIE_NAME,
          stringifiedResumeConfig,
          TOKEN_TIMEOUT,
        );

        dispatch({
          type: UPDATE_RESUME,
          value: resumeConfig,
        });
      }

      if (
        userClaimStatus !== STATUS_CUSTOMER_IN_PROGRESS &&
        userClaimStatus !== STATUS_CUSTOMER_RETAKE
      ) {
        deleteCookie(RETAKE_DATA_COOKIE_NAME);
        deleteCookie(RESUME_DATA_COOKIE_NAME);

        dispatch({
          type: UPDATE_RETAKE,
          value: undefined,
        });

        dispatch({
          type: UPDATE_RESUME,
          value: undefined,
        });
      }
    };
  },

  resendSmsConfirmationCode() {
    return () => generateSmsConfirmationCode(true);
  },
};
