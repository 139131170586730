import PropTypes from 'prop-types';
import {
  HIDE_APP_LOADER,
  SHOW_APP_LOADER,
  UPDATE_DISPLAY_HINTS,
} from './ActionTypes';

export default {
  PropertyTypes: PropTypes.shape({
    showAppLoader: PropTypes.func.isRequired,
    hideAppLoader: PropTypes.func.isRequired,
    updateDisplayHints: PropTypes.func.isRequired,
  }),

  showAppLoader(translucent, message) {
    return {
      type: SHOW_APP_LOADER,
      translucent,
      message,
    };
  },

  hideAppLoader() {
    return {
      type: HIDE_APP_LOADER,
    };
  },

  updateDisplayHints(displayHints) {
    return {
      type: UPDATE_DISPLAY_HINTS,
      value: displayHints,
    };
  },
};
