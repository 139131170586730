import {
  HIDE_APP_LOADER,
  SHOW_APP_LOADER,
  UPDATE_DISPLAY_HINTS,
  UPDATE_RETAKE,
  UPDATE_RESUME,
  UPDATE_INVALID_STATUS,
} from '../actions/ActionTypes';
import { getEnvironmentConfig } from '../config/environments';

export const initialState = {
  environment: getEnvironmentConfig(),
  displayHints: true,
  appLoader: {
    displayed: false,
    translucent: undefined,
    message: undefined,
  },
  retake: undefined,
  resume: undefined,
  invalidStatus: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_APP_LOADER:
      return {
        ...state,
        appLoader: {
          displayed: true,
          translucent: action.translucent ? action.translucent : undefined,
          message: action.message ? action.message : undefined,
        },
      };
    case HIDE_APP_LOADER:
      return {
        ...state,
        appLoader: {
          displayed: false,
          translucent: undefined,
          message: undefined,
        },
      };
    case UPDATE_DISPLAY_HINTS:
      return { ...state, displayHints: action.value };
    case UPDATE_RETAKE:
      return { ...state, retake: action.value };
    case UPDATE_RESUME:
      return { ...state, resume: action.value };
    case UPDATE_INVALID_STATUS:
      return { ...state, invalidStatus: action.value };
    default:
      return state;
  }
};
