import { AlphanumericInput } from '@lmig/lmds-react';
import classNames from 'classnames';
import { useField } from 'informed';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const InformedAlphanumericInput = props => {
  const {
    className,
    id,
    labelVisual,
    onChange,
    initialValue,
    disabled,
  } = props;

  const {
    fieldState: { value, error },
    fieldApi: { setValue },
  } = useField({ ...props });

  const onChangeOrBlur = event => {
    const trimmedValue = event.target.value.trim();
    setValue(trimmedValue);
    onChange(event);
  };

  return (
    <AlphanumericInput
      id={id}
      className={classNames([
        className,
        {
          'validation-input-field-error': error,
        },
      ])}
      labelVisual={labelVisual}
      value={value || initialValue}
      onChange={onChangeOrBlur}
      onBlur={onChangeOrBlur}
      disabled={disabled}
    />
  );
};

InformedAlphanumericInput.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  labelVisual: PropTypes.string,
  onChange: PropTypes.func,
  initialValue: PropTypes.string,
  disabled: PropTypes.bool,
};

InformedAlphanumericInput.defaultProps = {
  className: null,
  labelVisual: null,
  onChange: () => {},
  initialValue: '',
  disabled: false,
};
export default InformedAlphanumericInput;
