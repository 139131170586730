import { utils } from '@lmig-latam/adlib-ui';
import { BodyText, Caption } from '@lmig/lmds-react';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getCountryCode, isMobileOrTablet } from '../../utils';
import localizedStrings from '../../utils/localizedStrings';
import './styles.scss';
import vigilado from './vigilado.svg';

const { formatString } = utils;

const {
  APP_FOOTER_HELP_LINE_TITLE,
  APP_FOOTER_TERMS_CONDITIONS_LINK,
  APP_FOOTER_COPYRIGHT,
  VIGILADO_IMAGE_ALT_TEXT,
} = localizedStrings;

const AppFooter = ({
  termsConditionsUrl,
  vigiladoLogoDisplayed,
  contactHelp,
  chatHelp,
  assistanLine,
}) => {
  const desktopClass = isMobileOrTablet() ? '' : ' app-footer-desktop';

  return (
    <div className={`app-footer${desktopClass}`}>
      {vigiladoLogoDisplayed && (
        <div className="app-footer-side">
          <img
            src={vigilado}
            className="app-footer-side-image"
            alt={VIGILADO_IMAGE_ALT_TEXT}
          />
        </div>
      )}
      {assistanLine && (
        <div className="app-footer-assistan-line ">
          <BodyText strong>
            <strong>{assistanLine.label}</strong>
            <a href={`${assistanLine.ref}`}>
              <span className="app-footer-terms-conditions">
                {assistanLine.contact}
              </span>
            </a>
          </BodyText>
        </div>
      )}

      <div className="app-footer-first-section">
        <BodyText strong>
          <strong>{APP_FOOTER_HELP_LINE_TITLE}</strong>
        </BodyText>
        {contactHelp.map(help => (
          <Caption key={help.name}>
            {`${help.name} `}
            <strong>
              <a href={`${help.ref}`}>
                <span className="app-footer-terms-conditions">
                  {help.contact}
                </span>
              </a>
            </strong>
          </Caption>
        ))}
      </div>
      <div className="app-footer-second-section">
        {chatHelp.map(help => (
          <Caption key={help.label}>
            {`${help.label} `}
            <strong>
              <a href={`${help.ref}`}>
                <span className="app-footer-terms-conditions">
                  {help.contact}
                </span>
              </a>
            </strong>
          </Caption>
        ))}
      </div>
      <div className="app-footer-second-section">
        <Caption>
          <strong>
            {formatString(APP_FOOTER_COPYRIGHT, {
              year: new Date().getFullYear(),
            })}
          </strong>
        </Caption>
        <div>
          <Caption>
            <strong>
              {termsConditionsUrl && (
                <a
                  href={termsConditionsUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="app-footer-terms-conditions">
                    {APP_FOOTER_TERMS_CONDITIONS_LINK}
                  </span>
                </a>
              )}
            </strong>
          </Caption>
        </div>
      </div>
    </div>
  );
};

AppFooter.propTypes = {
  termsConditionsUrl: PropTypes.string.isRequired,
  vigiladoLogoDisplayed: PropTypes.bool.isRequired,
  assistanLine: PropTypes.shape({
    label: PropTypes.string.isRequired,
    contact: PropTypes.string.isRequired,
    ref: PropTypes.string.isRequired,
  }).isRequired,
  contactHelp: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      contact: PropTypes.string.isRequired,
      ref: PropTypes.string.isRequired,
    }),
  ).isRequired,
  chatHelp: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      contact: PropTypes.string.isRequired,
      ref: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

const mapStateToProps = ({ settings: { environment } }) => {
  const {
    termsConditionsUrl,
    vigiladoLogoDisplayed,
    contactHelp,
    chatHelp,
    assistanLine,
  } = environment[getCountryCode()];
  return {
    termsConditionsUrl,
    vigiladoLogoDisplayed,
    contactHelp,
    chatHelp,
    assistanLine,
  };
};

export default connect(mapStateToProps, null)(AppFooter);
