import { LOG_LEVELS } from './logger';

const { ERROR, INFO, WARN } = LOG_LEVELS;

export default {
  CANAV010: {
    code: 'CANAV010',
    level: INFO,
    message: 'User has navigated',
  },
  CANAV100: {
    code: 'CANAV100',
    level: WARN,
    message:
      'Navigating because user loaded into middle of flow with no authToken cookie',
  },
  CANAV110: {
    code: 'CANAV110',
    level: WARN,
    message:
      'Navigating because user loaded into middle of flow with no emailToken cookie',
  },
  CANAV120: {
    code: 'CANAV120',
    level: WARN,
    message: 'Navigating because user is Unauthorized',
  },
  CANAV130: {
    code: 'CANAV130',
    level: WARN,
    message: 'Navigating because inspection is in an AGENT status',
  },
  CANAV140: {
    code: 'CANAV140',
    level: WARN,
    message: 'Navigating because inspection has already been APPROVED',
  },
  CANAV150: {
    code: 'CANAV150',
    level: WARN,
    message: 'Navigating because inspection has been REJECTED',
  },
  CANAV160: {
    code: 'CANAV160',
    level: WARN,
    message: 'Navigating because inspection has EXPIRED',
  },
  CANAV170: {
    code: 'CANAV170',
    level: ERROR,
    message: 'Navigating because inspection is in an UNKNOWN invalid status',
  },
  CAAPI110: {
    code: 'CAAPI110',
    level: ERROR,
    message: 'Error in service response',
  },
  CAAPI120: {
    code: 'CAAPI120',
    level: ERROR,
    message: 'Error calling service',
  },
  CAIMG010: {
    code: 'CAIMG010',
    level: INFO,
    message: 'Image compressed',
  },
  CAIMG020: {
    code: 'CAIMG020',
    level: INFO,
    message: 'Before compression',
  },
  CAIMG110: {
    code: 'CAIMG110',
    level: ERROR,
    message: 'Error compressing image',
  },
  CAUPL110: {
    code: 'CAUPL110',
    level: INFO,
    message: 'Finish inspection service resolved',
  },
  CAUPL120: {
    code: 'CAUPL120',
    level: ERROR,
    message: 'Error while uploading photo',
  },
  CAUPL130: {
    code: 'CAUPL130',
    level: INFO,
    message: 'Starting to upload photo',
  },
  CAUPL140: {
    code: 'CAUPL140',
    level: ERROR,
    message: 'Error when finishing inspection',
  },
  PHUB010: {
    code: 'PHUB010',
    level: INFO,
    message: 'Finish confirmation modal shown',
  },
  PHUB020: {
    code: 'PHUB020',
    level: INFO,
    message: 'Finalize button shown',
  },
  PHUB030: {
    code: 'PHUB030',
    level: INFO,
    message: 'Finalize button hidden',
  },
  PHUB040: {
    code: 'PHUB040',
    level: INFO,
    message: 'Add optional damage description',
  },
  PHUB050: {
    code: 'PHUB050',
    level: ERROR,
    message: 'Error saving damage description',
  },
  PHUB060: {
    code: 'PHUB060',
    level: INFO,
    message: 'Delete optional damage description',
  },
  PHUB070: {
    code: 'PHUB070',
    level: ERROR,
    message: 'Error deleting damage description',
  },
  CALAN110: {
    code: 'CALAN110',
    level: ERROR,
    message: 'Error selecting language',
  },
};
