import { Select, SelectOption } from '@lmig/lmds-react';
import classNames from 'classnames';
import { useField } from 'informed';
import PropTypes from 'prop-types';
import React from 'react';
import localizedStrings from '../../utils/localizedStrings';
import './styles.scss';

const { CLAIM_VEHICLE_DETAILS_SELECT_DEFAULT } = localizedStrings;

const InformedSelect = props => {
  const {
    className,
    id,
    labelVisual,
    onChange,
    initialValue,
    selectValues,
    disabled,
  } = props;

  const {
    fieldState: { value, error },
    fieldApi: { setValue },
  } = useField({ ...props });

  const onChangeOrBlur = event => {
    setValue(event.target.value);
    onChange(event);
  };

  return (
    <Select
      id={id}
      className={classNames([
        className,
        {
          'validation-select-field-error': error,
        },
      ])}
      labelVisual={labelVisual}
      value={value || initialValue}
      placeholderOption={CLAIM_VEHICLE_DETAILS_SELECT_DEFAULT}
      onChange={onChangeOrBlur}
      onBlur={onChangeOrBlur}
      disabled={disabled}
    >
      {selectValues.map(option => (
        <SelectOption value={option} key={`${id}-${option}`}>
          {option}
        </SelectOption>
      ))}
    </Select>
  );
};

InformedSelect.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  labelVisual: PropTypes.string,
  onChange: PropTypes.func,
  initialValue: PropTypes.string,
  selectValues: PropTypes.arrayOf(PropTypes.string).isRequired,
  disabled: PropTypes.bool,
};

InformedSelect.defaultProps = {
  className: null,
  labelVisual: null,
  onChange: () => {},
  initialValue: '',
  disabled: false,
};

export default InformedSelect;
