import { Disclaimer, Header as LibertyMutualHeader } from '@lmig/lmds-react';
import PropTypes from 'prop-types';
import React from 'react';
import libertyLogo from '../../styles/images/logo-liberty.svg';
import localizedStrings from '../../utils/localizedStrings';
import './styles.scss';

const { LIBERTY_LOGO_IMAGE_ALT_TEXT, APP_HEADER_SUBTITLE } = localizedStrings;

const AppHeader = ({ displaySubtitle }) => (
  <div>
    <LibertyMutualHeader className="align-center">
      <img src={libertyLogo} alt={LIBERTY_LOGO_IMAGE_ALT_TEXT} />
    </LibertyMutualHeader>
    {displaySubtitle && (
      <div className="subtitle-container">
        <Disclaimer className="subtitle">
          {APP_HEADER_SUBTITLE.toUpperCase()}
        </Disclaimer>
      </div>
    )}
  </div>
);

AppHeader.propTypes = {
  displaySubtitle: PropTypes.bool,
};

AppHeader.defaultProps = {
  displaySubtitle: false,
};

export default AppHeader;
