import history from './history';
import { logcodes, logger } from './logger';
import { checkForTokens } from './customErrors';

export const navigate = async (newPath, state) => {
  const {
    location: { pathname },
    push,
  } = history;

  // TODO figure out how to catch back button navigation
  checkForTokens();

  logger.log(logcodes.CANAV010, { from: pathname, to: newPath });

  window.scrollTo(0, 0);

  // https://github.com/ReactTraining/history/blob/v4/docs/Navigation.md
  push({ pathname: newPath, state, search: history.location.search });
};

export default {
  navigate,
};
