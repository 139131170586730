import frontDriverSide01 from './frontDriverSide/frontDriverSide_01.jpg';
import frontDriverSide02 from './frontDriverSide/frontDriverSide_02.jpg';
import RegistrationBackSide from './registration/backSide.jpg';
import RegistrationFrontSide from './registration/frontSide.jpg';
import damagedArea from './damagedArea/damagedArea_01.jpg';

const defaultPhotoImages = {
  step01: [frontDriverSide01, frontDriverSide02],
  step02: [RegistrationFrontSide],
  step03: [RegistrationBackSide],
  step04: [damagedArea],
  step05: [damagedArea],
  step06: [damagedArea],
  step07: [damagedArea],
  step08: [damagedArea],
};

export default {
  en: {
    ...defaultPhotoImages,
  },
  co: {
    ...defaultPhotoImages,
  },
  cl: {
    ...defaultPhotoImages,
  },
  ec: {
    ...defaultPhotoImages,
  },
};
