import { REQUIRED_PHOTO_IDS } from '@lmig-latam/adcl-common-lib/constants';
import { getCountryConfig } from '@lmig-latam/adcl-common-lib/countries';
import { getCountryCode, getSinglePhotoConfig } from '../utils';
import { store } from '../utils/configureStore';
import localizedStrings from '../utils/localizedStrings';
import requiredPhotosImages from './requiredPhotosImages';

const country = getCountryCode();

const { countryRequiredPhotoIds } = getCountryConfig(country);

const {
  PHOTO_HUB_STEP_01_TITLE,
  PHOTO_HUB_STEP_01_SUBTITLE,
  PHOTO_HUB_STEP_01_HINT,
  PHOTO_HUB_STEP_02_TITLE,
  PHOTO_HUB_STEP_02_SUBTITLE,
  PHOTO_HUB_STEP_02_HINT,
  PHOTO_HUB_STEP_03_TITLE,
  PHOTO_HUB_STEP_03_SUBTITLE,
  PHOTO_HUB_STEP_03_HINT,
  PHOTO_HUB_STEP_04_TITLE,
  PHOTO_HUB_STEP_04_SUBTITLE,
  PHOTO_HUB_STEP_04_HINT,
  PHOTO_HUB_STEP_05_TITLE,
  PHOTO_HUB_STEP_05_SUBTITLE,
  PHOTO_HUB_STEP_05_HINT,
  PHOTO_HUB_STEP_06_TITLE,
  PHOTO_HUB_STEP_06_SUBTITLE,
  PHOTO_HUB_STEP_06_HINT,
  PHOTO_HUB_STEP_07_TITLE,
  PHOTO_HUB_STEP_07_SUBTITLE,
  PHOTO_HUB_STEP_07_HINT,
  PHOTO_HUB_STEP_08_TITLE,
  PHOTO_HUB_STEP_08_SUBTITLE,
  PHOTO_HUB_STEP_08_HINT,
} = localizedStrings;

const {
  CLAIM_PHOTO_VEHICLE_FRONT,
  CLAIM_PHOTO_PROPERTY_CARD_FRONT,
  CLAIM_PHOTO_PROPERTY_CARD_BACK,
  CLAIM_PHOTO_VEHICLE_DAMAGE_ONE,
  CLAIM_PHOTO_VEHICLE_DAMAGE_TWO,
  CLAIM_PHOTO_VEHICLE_DAMAGE_THREE,
  CLAIM_PHOTO_VEHICLE_DAMAGE_FOUR,
  CLAIM_PHOTO_VEHICLE_DAMAGE_FIVE,
} = REQUIRED_PHOTO_IDS;

const defaultPhotoConfigs = [
  {
    photoId: CLAIM_PHOTO_VEHICLE_FRONT,
    title: PHOTO_HUB_STEP_01_TITLE,
    body: PHOTO_HUB_STEP_01_SUBTITLE,
    hints: {
      text: PHOTO_HUB_STEP_01_HINT,
      images: requiredPhotosImages[country].step01,
    },
  },
  {
    photoId: CLAIM_PHOTO_PROPERTY_CARD_FRONT,
    title: PHOTO_HUB_STEP_02_TITLE,
    body: PHOTO_HUB_STEP_02_SUBTITLE,
    hints: {
      text: PHOTO_HUB_STEP_02_HINT,
      images: requiredPhotosImages[country].step02,
    },
  },
  {
    photoId: CLAIM_PHOTO_PROPERTY_CARD_BACK,
    title: PHOTO_HUB_STEP_03_TITLE,
    body: PHOTO_HUB_STEP_03_SUBTITLE,
    hints: {
      text: PHOTO_HUB_STEP_03_HINT,
      images: requiredPhotosImages[country].step03,
    },
  },
  {
    photoId: CLAIM_PHOTO_VEHICLE_DAMAGE_ONE,
    title: PHOTO_HUB_STEP_04_TITLE,
    body: PHOTO_HUB_STEP_04_SUBTITLE,
    hints: {
      text: PHOTO_HUB_STEP_04_HINT,
      images: requiredPhotosImages[country].step04,
    },
  },
  {
    photoId: CLAIM_PHOTO_VEHICLE_DAMAGE_TWO,
    title: PHOTO_HUB_STEP_05_TITLE,
    body: PHOTO_HUB_STEP_05_SUBTITLE,
    hints: {
      text: PHOTO_HUB_STEP_05_HINT,
      images: requiredPhotosImages[country].step05,
    },
  },
  {
    photoId: CLAIM_PHOTO_VEHICLE_DAMAGE_THREE,
    title: PHOTO_HUB_STEP_06_TITLE,
    body: PHOTO_HUB_STEP_06_SUBTITLE,
    hints: {
      text: PHOTO_HUB_STEP_06_HINT,
      images: requiredPhotosImages[country].step06,
    },
  },
  {
    photoId: CLAIM_PHOTO_VEHICLE_DAMAGE_FOUR,
    title: PHOTO_HUB_STEP_07_TITLE,
    body: PHOTO_HUB_STEP_07_SUBTITLE,
    hints: {
      text: PHOTO_HUB_STEP_07_HINT,
      images: requiredPhotosImages[country].step07,
    },
  },
  {
    photoId: CLAIM_PHOTO_VEHICLE_DAMAGE_FIVE,
    title: PHOTO_HUB_STEP_08_TITLE,
    body: PHOTO_HUB_STEP_08_SUBTITLE,
    hints: {
      text: PHOTO_HUB_STEP_08_HINT,
      images: requiredPhotosImages[country].step08,
    },
  },
];

const unformattedRequiredPhotos = countryRequiredPhotoIds.map(
  photoId =>
    defaultPhotoConfigs.filter(
      photoConfig => photoConfig.photoId === photoId,
    )[0],
);
// We need to build a dynamic 'requiredPhotos' set to accommodate the 'retake' flow where a previously optional accessory photo needs to be retaken
export const buildRequiredPhotos = () => {
  const {
    settings: { retake, resume },
  } = store.getState();

  let requiredPhotos;

  if (resume) {
    requiredPhotos = [...unformattedRequiredPhotos].filter(({ photoId }) =>
      resume.required.includes(photoId),
    );
  } else if (retake) {
    // each member of retake.required will have a config object that can be used
    requiredPhotos = [...unformattedRequiredPhotos].filter(({ photoId }) =>
      [...retake.required].includes(photoId),
    );

    // each member of retake.accessories will have to have a config object created since they have arbitrary names
    requiredPhotos = [
      ...requiredPhotos,
      ...retake.accessories.map(photoId => ({
        photoId,
        title: photoId,
      })),
    ];
  } else {
    requiredPhotos = unformattedRequiredPhotos;
  }
  return requiredPhotos;
};

export const getRequiredPhoto = requestedId =>
  getSinglePhotoConfig(requestedId, buildRequiredPhotos());

export const requiredPhotosPhotoIdList = buildRequiredPhotos().map(
  ({ photoId }) => photoId,
);

export default buildRequiredPhotos();
