import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Form } from 'informed';
import { OptionalPhotoList } from '..';
import OptionalSectionPanel from './OptionalSectionPanel';
import OptionalSectionModal from './ModalOptionalSection';
import './styles.scss';

const OptionalPhotosSection = ({
  navigate,
  optionalPhotosTaken,
  deletePhoto,
}) => {
  // This will be used to get a handle on the <input> element inside CapturePhoto
  const [dataModal, setDataModal] = useState(null);
  const [optionalModalDisplayed, setOptionalPhotoModalDisplayed] = useState(
    false,
  );

  const [inlineLoaderDisplayed, setInlineLoaderDisplayed] = useState(false);

  const showOptionalModal = (data, key) => {
    setDataModal({ ...data, key });
    setOptionalPhotoModalDisplayed(true);
  };

  const hideOptionalPhotoModal = () => {
    setOptionalPhotoModalDisplayed(false);
  };

  const showInlineLoader = flag => {
    setInlineLoaderDisplayed(flag);
  };

  const renderOptionalPhotoModal = () => (
    <Form autoComplete="off">
      <OptionalSectionModal
        dataModal={dataModal}
        optionalModalDisplayed={optionalModalDisplayed}
        hideOptionalPhotoModal={hideOptionalPhotoModal}
        showInlineLoader={showInlineLoader}
      />
    </Form>
  );

  return (
    <div className="optional-photos-section">
      {dataModal && renderOptionalPhotoModal()}

      <OptionalSectionPanel
        showOptionalModal={showOptionalModal}
        inlineLoaderDisplayed={inlineLoaderDisplayed}
      />

      <OptionalPhotoList
        photos={optionalPhotosTaken}
        navigate={navigate}
        deletePhoto={deletePhoto}
      />
    </div>
  );
};

OptionalPhotosSection.propTypes = {
  optionalPhotosTaken: PropTypes.arrayOf(PropTypes.object).isRequired,
  navigate: PropTypes.func.isRequired,
  deletePhoto: PropTypes.func.isRequired,
};

export default OptionalPhotosSection;
