import React from 'react';
import PropTypes from 'prop-types';
import { Disclaimer, GridCol, GridRow, Button } from '@lmig/lmds-react';
import { Textarea } from '..';
import './styles.scss';
import localizedStrings from '../../utils/localizedStrings';

const {
  PHOTO_HUB_OPTIONAL_DESCRIPTION_DAMAGE_SECTION_CANCEL_LABEL_BUTTON,
} = localizedStrings;

const WriterSection = ({
  section,
  description,
  setDescription,
  disablePrimaryButton,
  onSaveDescription,
  onCancel,
}) => {
  const limitText = 500;

  const getSubMessageTextarea = () => (
    <spam className="counter-character">{`${description.length} de ${limitText} caracteres`}</spam>
  );

  return (
    <>
      <GridRow vAlign="middle">
        <GridCol>
          <Textarea
            rows={4}
            maxLength={500}
            value={description}
            placeholder={section.inputPlaceholder}
            onChange={value => setDescription(value)}
          />
          <Disclaimer className="gray-text">
            {getSubMessageTextarea()}
          </Disclaimer>
        </GridCol>
      </GridRow>

      <div className="align-center modal-primary-button save-description-button">
        <Button
          type="button"
          level="primary"
          onClick={() => onSaveDescription()}
          disabled={disablePrimaryButton}
        >
          {section.primaryButton}
        </Button>
      </div>
      <div className="align-center modal-secondary-button save-description-button">
        <button
          type="button"
          className="delete-button"
          onClick={() => onCancel()}
        >
          {PHOTO_HUB_OPTIONAL_DESCRIPTION_DAMAGE_SECTION_CANCEL_LABEL_BUTTON}
        </button>
      </div>
    </>
  );
};

WriterSection.propTypes = {
  description: PropTypes.string.isRequired,
  section: PropTypes.shape({
    primaryButton: PropTypes.string.isRequired,
    inputPlaceholder: PropTypes.string.isRequired,
  }).isRequired,
  setDescription: PropTypes.func.isRequired,
  onSaveDescription: PropTypes.func.isRequired,
  disablePrimaryButton: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};
export default WriterSection;
