/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import Button from '@lmig/lmds-react/Button/Button';
import Disclaimer from '@lmig/lmds-react/Typography/Disclaimer';
import { STATE_DAMAGE_DESCRIPTION_SECTION } from './DamageDescriptionSection';
import localizedStrings from '../../utils/localizedStrings';
import './styles.scss';

const {
  PHOTO_HUB_OPTIONAL_DESCRIPTION_DAMAGE_SECTION_DELETE_LABEL_BUTTON,
  PHOTO_HUB_OPTIONAL_DESCRIPTION_DAMAGE_SECTION_EDIT_LABEL_BUTTON,
} = localizedStrings;

function PreviewDamageDescription({ description, onOpenModal, setStatus }) {
  const onEdit = () => setStatus(STATE_DAMAGE_DESCRIPTION_SECTION.IN_WRITE);

  return (
    <div>
      <Disclaimer>{description}</Disclaimer>
      <div className="align-center modal-primary-button save-description-button">
        <Button type="button" level="primary" onClick={() => onEdit()}>
          {PHOTO_HUB_OPTIONAL_DESCRIPTION_DAMAGE_SECTION_EDIT_LABEL_BUTTON}
        </Button>
      </div>

      <div className="align-center modal-secondary-button save-description-button">
        <button
          type="button"
          className="delete-button"
          onClick={() => onOpenModal()}
        >
          {PHOTO_HUB_OPTIONAL_DESCRIPTION_DAMAGE_SECTION_DELETE_LABEL_BUTTON}
        </button>
      </div>
    </div>
  );
}

PreviewDamageDescription.propTypes = {
  description: PropTypes.string.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  setStatus: PropTypes.func.isRequired,
};

export default PreviewDamageDescription;
