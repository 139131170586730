import { UPDATE_USER_DETAILS } from '../actions/ActionTypes';

export const initialState = {
  authToken: '',
  maskedCellPhoneNumber: '',
  userClaimStatus: '',
  userDamageDescription: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_USER_DETAILS:
      return {
        ...state,
        maskedCellPhoneNumber: action.value.maskedCellPhoneNumber,
        userClaimStatus: action.value.userClaimStatus,
        userDamageDescription: action.value.userDamageDescription,
      };
    default:
      return state;
  }
};
