import { combineReducers } from 'redux';
import photosReducer from './photosReducer';
import settingsReducer from './settingsReducer';
import userReducer from './userReducer';

export default combineReducers({
  photos: photosReducer,
  settings: settingsReducer,
  user: userReducer,
});
