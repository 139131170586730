import constants from '@lmig-latam/adcl-common-lib/constants/constants';

const {
  LANGUAGES: { CL },
} = constants;

export default {
  cl: {
    termsConditionsUrl: 'https://www.liberty.cl/terminos-de-uso.html',
    privacyPolicyUrl: 'https://www.liberty.cl/terminos-de-uso.html',
    satisfactionSurveyRedirectUrl:
      'https://adcl.libertyseguros.cl/app/complete?utm_source=adil&utm_medium=finalButton',
    processCompleteRedirectUrl:
      'https://www.liberty.cl/centro-siniestro-vehiculo.html?utm_source=adcl&utm_medium=finalButton',
    businessHours: {
      businessHoursEnabled: true,
      businessHoursEnd: 18,
      businessHoursStart: 6,
      weekendsAreOutsideOfBusinessHours: true,
    },
    vigiladoLogoDisplayed: false,
    assistanLine: '',
    contactHelp: [
      { name: 'Llámanos', contact: '600 542 3789', ref: 'tel: 600 542 3789' },
    ],
    chatHelp: [
      {
        label: 'WhatsApp',
        contact: '+56 9 4022 2303',
        ref: 'https://wa.me/56940222303',
      },
    ],
  },
  co: {
    termsConditionsUrl:
      'https://www.libertyseguros.co/sites/default/files/2020-05/TE%CC%81RMINOS%20DE%20USO%20Y%20PRIVACIDAD.pdf',
    privacyPolicyUrl:
      'https://www.libertycolombia.com.co/Paginas/Terminos-de-Uso-y-Privacidad.aspx',
    satisfactionSurveyRedirectUrl:
      'https://adcl.libertyseguros.co/app/complete?utm_source=adil&utm_medium=finalButton',
    processCompleteRedirectUrl:
      'https://libertyseguros.co/siniestros/informacion-de-siniestros?utm_source=adcl&utm_medium=finalButton',
    businessHours: {
      businessHoursEnabled: true,
      businessHoursEnd: 18,
      businessHoursStart: 6,
      weekendsAreOutsideOfBusinessHours: true,
    },
    vigiladoLogoDisplayed: true,
    assistanLine: { label: 'Asistencia ', contact: '#224', ref: 'tel: #224' },
    contactHelp: [
      { name: 'Bogotá', contact: '(57 1) 3077050', ref: 'tel: 5713077050' },
      {
        name: 'Resto del país',
        contact: '01 8000 113390',
        ref: 'tel: 01 8000 113390',
      },
    ],
    chatHelp: [
      {
        label: 'WhatsApp',
        contact: '+57 316 4821802',
        ref: 'https://wa.me/573164821802',
      },
    ],
  },
  ec: {
    termsConditionsUrl:
      'https://www.libertyseguros.ec/terminos-de-uso-y-privacidad',
    privacyPolicyUrl:
      'https://www.libertyseguros.ec/terminos-de-uso-y-privacidad',
    satisfactionSurveyRedirectUrl:
      'https://adcl.libertyseguros.ec/app/complete?utm_source=adil&utm_medium=finalButton',
    processCompleteRedirectUrl:
      'https://libertyseguros.ec/siniestros/informacion-de-siniestros?utm_source=adcl&utm_medium=finalButton',
    businessHours: {
      businessHoursEnabled: true,
      businessHoursEnd: 18,
      businessHoursStart: 6,
      weekendsAreOutsideOfBusinessHours: true,
    },
    vigiladoLogoDisplayed: false,
    assistanLine: '',
    contactHelp: [
      { name: 'Quito', contact: '(593 2) 3932100', ref: 'tel: 593 2 3932100' },
      {
        name: 'Resto del país',
        contact: '1800-542378',
        ref: 'tel: 1800-542378',
      },
    ],
    chatHelp: [
      {
        label: 'Escríbenos por el chat',
        contact: 'www.libertyseguros.ec',
        ref: 'https://www.libertyseguros.ec',
      },
    ],
  },
  enablePhotoAutofill: false,
  language: CL,
};
