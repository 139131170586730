import {
  BodyText,
  Caption,
  Content,
  Header,
  Heading,
  IconSmartphone,
} from '@lmig/lmds-react';
import React from 'react';
import { AppFooter, CircleImage } from '../../components';
import libertyLogo from '../../styles/images/logo-liberty.svg';
import localizedStrings from '../../utils/localizedStrings';
import './styles.scss';

const {
  LIBERTY_LOGO_IMAGE_ALT_TEXT,
  NON_MOBILE_ERROR_HEADER_TITLE,
  NON_MOBILE_ERROR_MESSAGE_TITLE,
  NON_MOBILE_ERROR_MESSAGE_SUBTITLE,
  NON_MOBILE_ERROR_MESSAGE_LINE_1,
  NON_MOBILE_ERROR_MESSAGE_PHONE_NUMBER_1,
  NON_MOBILE_ERROR_MESSAGE_LINE_2,
  NON_MOBILE_ERROR_MESSAGE_PHONE_NUMBER_2,
  NON_MOBILE_ERROR_MESSAGE_LINE_3,
} = localizedStrings;

const NonMobileError = () => (
  <div className="adcl-screen">
    <Header>
      <img
        className="non-mobile-error-header-image"
        src={libertyLogo}
        alt={LIBERTY_LOGO_IMAGE_ALT_TEXT}
      />
      <span className="non-mobile-error-header-title">
        {NON_MOBILE_ERROR_HEADER_TITLE}
      </span>
    </Header>
    <div className="adcl-content non-mobile-error-main-content">
      <Content className="non-mobile-error-content">
        <Heading type="h3-light" className="auto-left-right-margin">
          {NON_MOBILE_ERROR_MESSAGE_TITLE}
        </Heading>
        <BodyText className="non-mobile-error-subtitle auto-left-right-margin">
          {NON_MOBILE_ERROR_MESSAGE_SUBTITLE}
        </BodyText>
        <CircleImage>
          <IconSmartphone sizing="auto" />
        </CircleImage>
        <Caption className="non-mobile-error-caption">
          <div>
            <span>{NON_MOBILE_ERROR_MESSAGE_LINE_1}</span>
            <a
              href={`tel:${NON_MOBILE_ERROR_MESSAGE_PHONE_NUMBER_1}`}
              className="non-mobile-error-phone-link"
            >
              {NON_MOBILE_ERROR_MESSAGE_PHONE_NUMBER_1}
            </a>
            <span>{NON_MOBILE_ERROR_MESSAGE_LINE_2}</span>
            <a
              href={`tel:${NON_MOBILE_ERROR_MESSAGE_PHONE_NUMBER_2}`}
              className="non-mobile-error-phone-link"
            >
              {NON_MOBILE_ERROR_MESSAGE_PHONE_NUMBER_2}
            </a>
          </div>
          <div>
            <span>{NON_MOBILE_ERROR_MESSAGE_LINE_3}</span>
          </div>
        </Caption>
      </Content>
    </div>
    <AppFooter />
  </div>
);

export default NonMobileError;
