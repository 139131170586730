import cl from './chilean';
import co from './colombian';
import ec from './ecuadorian';
import en from './english';

// When localizing for a new language, add it to this object, everything else will be taken care of
export const languageConfigs = {
  cl,
  co,
  ec,
  en,
};

export default languageConfigs;
