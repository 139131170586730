import React, { useState, useEffect } from 'react';
import { BodyText, GridCol, GridRow } from '@lmig/lmds-react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { store } from '../../utils/configureStore';
import { InlineLoader, Panel } from '..';
import { OptionalDescriptionActions } from '../../actions';
import { logcodes, logger } from '../../utils/logger';
import localizedStrings from '../../utils/localizedStrings';
import PreviewDamageDescription from './PreviewDamageDescription';
import ModalDamageDescription from './ModalDamageDescription';
import NotWriterSection from './NotWriterSection';
import WriterSection from './WriterSection';
import './styles.scss';

export const STATE_DAMAGE_DESCRIPTION_SECTION = {
  NON_WRITE: 'NON_WRITE',
  IN_PREVIEW: 'IN_PREVIEW',
  IN_WRITE: 'IN_WRITE',
};

const {
  SAVING_TEXT,
  PHOTO_HUB_OPTIONAL_DESCRIPTION_DAMAGE_SECTION_TITLE,
  PHOTO_HUB_OPTIONAL_DESCRIPTION_DAMAGE_SECTION_PANEL_TITLE,
  PHOTO_HUB_OPTIONAL_DESCRIPTION_DAMAGE_SECTION_PANEL_SUBTITLE,
  PHOTO_HUB_OPTIONAL_DESCRIPTION_DAMAGE_SECTION_MODAL_INPUT_DESCRIPTION,
  PHOTO_HUB_OPTIONAL_DESCRIPTION_DAMAGE_SECTION_MODAL_PRIMARY_BUTTON_LABEL,
} = localizedStrings;

const damageDescription = {
  title: PHOTO_HUB_OPTIONAL_DESCRIPTION_DAMAGE_SECTION_TITLE,
  section: {
    title: PHOTO_HUB_OPTIONAL_DESCRIPTION_DAMAGE_SECTION_PANEL_TITLE,
    subtitle: PHOTO_HUB_OPTIONAL_DESCRIPTION_DAMAGE_SECTION_PANEL_SUBTITLE,
    inputPlaceholder: PHOTO_HUB_OPTIONAL_DESCRIPTION_DAMAGE_SECTION_MODAL_INPUT_DESCRIPTION,
    primaryButton: PHOTO_HUB_OPTIONAL_DESCRIPTION_DAMAGE_SECTION_MODAL_PRIMARY_BUTTON_LABEL,
  },
};

function DamageDescriptionContainer({
  OptionalDescriptionActions: { addDamageDescription, deleteDamageDescription },
}) {
  const { title, section } = damageDescription;
  const [inlineLoaderDisplayed, setInlineLoaderDisplayed] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [description, setDescription] = useState('');
  const [oldDescription, setOldDescription] = useState('');
  const [statusDescriptionSection, setStatusDescriptionSection] = useState(
    STATE_DAMAGE_DESCRIPTION_SECTION.NON_WRITE,
  );
  useEffect(() => {
    const {
      user: { userDamageDescription },
    } = store.getState();
    setDescription(userDamageDescription);
    setOldDescription(userDamageDescription);
    if (userDamageDescription.length !== 0) {
      section.subtitle = userDamageDescription;
      if (userDamageDescription.length > 100) {
        section.subtitle = userDamageDescription.slice(0, 100);
      }
      setStatusDescriptionSection(STATE_DAMAGE_DESCRIPTION_SECTION.IN_PREVIEW);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isNonWrite =
    statusDescriptionSection === STATE_DAMAGE_DESCRIPTION_SECTION.NON_WRITE;

  const isWriter =
    statusDescriptionSection === STATE_DAMAGE_DESCRIPTION_SECTION.IN_WRITE;

  const isPreview =
    statusDescriptionSection === STATE_DAMAGE_DESCRIPTION_SECTION.IN_PREVIEW;

  const onCloseModal = () => setOpenModal(false);

  const onOpenModal = () => setOpenModal(true);

  const disablePrimaryButton =
    description.trim() === '' || oldDescription.trim() === description.trim();

  const onDeleteDescription = async () => {
    try {
      setInlineLoaderDisplayed(true);
      await deleteDamageDescription(null);
      setInlineLoaderDisplayed(false);
      logger.log(logcodes.PHUB060, null);
      setStatusDescriptionSection(STATE_DAMAGE_DESCRIPTION_SECTION.IN_WRITE);
      setDescription('');
      setOldDescription('');
      onCloseModal();
    } catch (error) {
      setInlineLoaderDisplayed(false);
      logger.log(logcodes.PHUB070, error);
    }
  };

  const onSaveDescription = async () => {
    try {
      if (oldDescription.trim() !== description.trim()) {
        setInlineLoaderDisplayed(true);
        await addDamageDescription(description);
        logger.log(logcodes.PHUB040, description);
        setStatusDescriptionSection(
          STATE_DAMAGE_DESCRIPTION_SECTION.IN_PREVIEW,
        );
        setInlineLoaderDisplayed(false);
        setOldDescription(description);
        section.subtitle = description;
      }
    } catch (error) {
      setInlineLoaderDisplayed(false);
      logger.log(logcodes.PHUB050, error);
    }
  };

  const onEdit = () => {
    setStatusDescriptionSection(STATE_DAMAGE_DESCRIPTION_SECTION.IN_WRITE);
    onCloseModal();
  };

  const cancelAddDescription = () => {
    if (oldDescription.length === 0) {
      setStatusDescriptionSection(STATE_DAMAGE_DESCRIPTION_SECTION.NON_WRITE);
      setDescription('');
    } else {
      setStatusDescriptionSection(STATE_DAMAGE_DESCRIPTION_SECTION.IN_PREVIEW);
    }
  };

  return (
    <div className="optional-photos-section">
      <BodyText className="photo-hub-section-title">{title}</BodyText>
      <Panel>
        {!isPreview && (
          <GridRow vAlign="middle">
            <GridCol>
              <BodyText className="card-container" type="lead">
                {section.title}
              </BodyText>
            </GridCol>
          </GridRow>
        )}

        {isNonWrite && (
          <NotWriterSection
            subtitle={section.subtitle}
            setStatus={setStatusDescriptionSection}
          />
        )}

        {isWriter && (
          <WriterSection
            section={section}
            description={description}
            setDescription={setDescription}
            onCancel={cancelAddDescription}
            onSaveDescription={onSaveDescription}
            disablePrimaryButton={disablePrimaryButton}
          />
        )}

        {isPreview && (
          <PreviewDamageDescription
            description={description}
            setStatus={setStatusDescriptionSection}
            onOpenModal={onOpenModal}
          />
        )}

        <GridRow>
          <GridCol>
            <div className="photo-loader">
              <InlineLoader
                isLoading={inlineLoaderDisplayed}
                showAnimation={false}
                message={SAVING_TEXT}
              />
            </div>
          </GridCol>
        </GridRow>
      </Panel>

      {openModal && (
        <ModalDamageDescription
          onDelete={onDeleteDescription}
          isOpenModal={openModal}
          onCloseModal={onCloseModal}
          onEdit={onEdit}
        />
      )}
    </div>
  );
}

const mapDispatchToProps = dispatch => ({
  OptionalDescriptionActions: bindActionCreators(
    OptionalDescriptionActions,
    dispatch,
  ),
});

export default connect(null, mapDispatchToProps)(DamageDescriptionContainer);

DamageDescriptionContainer.propTypes = {
  OptionalDescriptionActions: PropTypes.shape({
    addDamageDescription: PropTypes.func.isRequired,
    deleteDamageDescription: PropTypes.func.isRequired,
  }).isRequired,
};
