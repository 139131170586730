import { utils } from '@lmig-latam/adlib-ui';
import {
  BodyText,
  Button,
  Caption,
  Content,
  GridCol,
  GridRow,
  Heading,
  IconMulticar,
} from '@lmig/lmds-react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Toggle } from '@lmig/lmds-react-toggle';
import { bindActionCreators } from 'redux';
import { REQUIRED_PHOTO_IDS } from '@lmig-latam/adcl-common-lib/constants';
import { PhotoHubActions, SettingsActions } from '../../actions';
import {
  AppFooter,
  AppHeader,
  CircleImage,
  Modal,
  OptionalPhotosSection,
  PhotoHubSection,
  DamageDescriptionCard,
} from '../../components';
import { buildRequiredPhotos } from '../../config/requiredPhotos';
import localizedStrings from '../../utils/localizedStrings';
import { navigate } from '../../utils/NavigationUtils';
import { loadTestImages } from '../../utils/test-data/photos';
import { logcodes, logger } from '../../utils/logger';
import photosTakenIcon from './photos-taken-icon.svg';
import './styles.scss';

const { formatString } = utils;

const {
  PHOTO_HUB_CONTINUE_BUTTON,
  PHOTO_HUB_FOOTER_PROGRESS,
  PHOTO_HUB_HINTS_TOGGLE_ENABLED_LABEL,
  PHOTO_HUB_HINTS_TOGGLE_DISABLED_LABEL,
  PHOTO_HUB_SUBTITLE,
  PHOTO_HUB_TITLE,
  PHOTO_HUB_LOAD_TEST_IMAGES_BUTTON_LABEL,
  PHOTO_HUB_CONFIRM_FINISH_MODAL_PRIMARY_BUTTON,
  PHOTO_HUB_CONFIRM_FINISH_MODAL_SECONDARY_BUTTON,
  PHOTO_HUB_CONFIRM_FINISH_MODAL_TITLE,
  PHOTO_HUB_CONFIRM_FINISH_MODAL_SUBTITLE,
  PHOTO_HUB_PHOTOS_TAKEN_MODAL_PRIMARY_BUTTON,
  PHOTO_HUB_PHOTOS_TAKEN_MODAL_SECONDARY_BUTTON,
  PHOTO_HUB_PHOTOS_TAKEN_MODAL_TITLE,
  PHOTO_HUB_PHOTOS_TAKEN_MODAL_SUBTITLE,
  PHOTO_HUB_SECTION_GENERAL_REQUIRED_PHOTOS_TITLE,
  PHOTO_HUB_SECTION_GENERAL_REQUIRED_PHOTOS_SUBTITLE,
  PHOTO_HUB_SECTION_DAMAGE_REQUIRED_PHOTOS_TITLE,
  PHOTO_HUB_SECTION_DAMAGE_REQUIRED_PHOTOS_SUBTITLE,
  PHOTO_HUB_SECTION_RETAKE_PHOTOS_TITLE,
  PHOTO_HUB_SECTION_RETAKE_PHOTOS_SUBTITLE,
} = localizedStrings;

const PhotoHub = ({
  settingsActions: { updateDisplayHints },
  photoHubActions: { deletePhoto, finishProcess },
  settings: {
    displayHints,
    environment: { enablePhotoAutofill },
    retake,
  },
  requiredPhotosTaken,
  optionalPhotosTaken,
}) => {
  const [
    finishConfirmationModalDisplayed,
    setFinishConfirmationModalDisplayed,
  ] = useState(false);

  const [
    hasRequiredPhotosTakenModalBeenShown,
    setHasRequiredPhotosTakenModalBeenShown,
  ] = useState(false);

  const [
    requiredPhotosTakenModalDisplayed,
    setRequiredPhotosTakenModalDisplayed,
  ] = useState(false);

  const {
    CLAIM_PHOTO_VEHICLE_DAMAGE_ONE,
    CLAIM_PHOTO_VEHICLE_DAMAGE_TWO,
    CLAIM_PHOTO_VEHICLE_DAMAGE_THREE,
    CLAIM_PHOTO_VEHICLE_DAMAGE_FOUR,
    CLAIM_PHOTO_VEHICLE_DAMAGE_FIVE,
  } = REQUIRED_PHOTO_IDS;

  const vehicleDamagePhotoIds = [
    CLAIM_PHOTO_VEHICLE_DAMAGE_ONE,
    CLAIM_PHOTO_VEHICLE_DAMAGE_TWO,
    CLAIM_PHOTO_VEHICLE_DAMAGE_THREE,
    CLAIM_PHOTO_VEHICLE_DAMAGE_FOUR,
    CLAIM_PHOTO_VEHICLE_DAMAGE_FIVE,
  ];

  const photosTaken = requiredPhotosTaken.length; // whats this about
  const photosRequired = buildRequiredPhotos().length;
  const allRequiredPhotosTaken = photosTaken === photosRequired;

  const toggleHints = event => {
    updateDisplayHints(event.target.checked);
  };

  const showFinishConfirmationModal = () => {
    logger.log(logcodes.PHUB010);
    setFinishConfirmationModalDisplayed(true);
  };

  const hideFinishConfirmationModal = () => {
    setFinishConfirmationModalDisplayed(false);
  };

  const showRequiredPhotosTakenModal = () => {
    if (!hasRequiredPhotosTakenModalBeenShown) {
      setRequiredPhotosTakenModalDisplayed(true);
      setHasRequiredPhotosTakenModalBeenShown(true);
    }
  };

  const hideRequiredPhotosTakenModal = () => {
    setRequiredPhotosTakenModalDisplayed(false);
  };

  const confirmFinish = () => {
    hideFinishConfirmationModal();
    finishProcess();
  };

  const getGeneralRequiredPhotos = () =>
    buildRequiredPhotos().filter(
      photoConfig => !vehicleDamagePhotoIds.includes(photoConfig.photoId),
    );

  const getDamageRequiredPhotos = () =>
    buildRequiredPhotos().filter(photoConfig =>
      vehicleDamagePhotoIds.includes(photoConfig.photoId),
    );

  const getGeneralRequiredPhotosTaken = requiredPhotosTaken.filter(
    takenPhoto => !vehicleDamagePhotoIds.includes(takenPhoto.photoId),
  );

  const getDamageRequiredPhotosTaken = requiredPhotosTaken.filter(takenPhoto =>
    vehicleDamagePhotoIds.includes(takenPhoto.photoId),
  );

  if (allRequiredPhotosTaken) {
    showRequiredPhotosTakenModal();
  }

  const renderHintsToggle = () => (
    <div>
      <Toggle
        checked={displayHints}
        icons="false"
        labelVisual={
          displayHints
            ? PHOTO_HUB_HINTS_TOGGLE_ENABLED_LABEL
            : PHOTO_HUB_HINTS_TOGGLE_DISABLED_LABEL
        }
        className="adcl-toggle"
        onChange={toggleHints}
        compact
        showOnOff={false}
      />
    </div>
  );

  const renderTestDataButton = () => {
    // Show the button if the environment allows it AND the app is not in retake mode
    const showTestDataButton = enablePhotoAutofill && !retake;

    return (
      showTestDataButton && (
        <Button
          type="button"
          className="photohub-test-data-button"
          onClick={loadTestImages}
        >
          {PHOTO_HUB_LOAD_TEST_IMAGES_BUTTON_LABEL}
        </Button>
      )
    );
  };

  const renderFooterContents = () => {
    let centerComponent;

    if (allRequiredPhotosTaken) {
      logger.log(logcodes.PHUB020);

      centerComponent = (
        <Button
          size="small"
          type="button"
          onClick={showFinishConfirmationModal}
        >
          {PHOTO_HUB_CONTINUE_BUTTON}
        </Button>
      );
    } else {
      logger.log(logcodes.PHUB030);

      centerComponent = (
        <Caption>
          {formatString(PHOTO_HUB_FOOTER_PROGRESS, {
            photosTaken: `${photosTaken}`,
            photosRequired: `${photosRequired}`,
          })}
        </Caption>
      );
    }

    return (
      <GridRow className="photohub-footer">
        <GridCol className="center-vertically">{centerComponent}</GridCol>
      </GridRow>
    );
  };

  const renderFinishConfirmationModal = () => (
    <Modal
      isOpen={finishConfirmationModalDisplayed}
      primaryButtonLabel={PHOTO_HUB_CONFIRM_FINISH_MODAL_PRIMARY_BUTTON}
      primaryButtonHandler={confirmFinish}
      secondaryButtonHandler={hideFinishConfirmationModal}
      secondaryButtonLabel={PHOTO_HUB_CONFIRM_FINISH_MODAL_SECONDARY_BUTTON}
      centerContent
    >
      <div>
        <Heading type="h3-light" className="auto-left-right-margin">
          {PHOTO_HUB_CONFIRM_FINISH_MODAL_TITLE}
        </Heading>

        <BodyText>{PHOTO_HUB_CONFIRM_FINISH_MODAL_SUBTITLE}</BodyText>

        <div className="auto-left-right-margin">
          <CircleImage>
            <IconMulticar sizing="auto" />
          </CircleImage>
        </div>
      </div>
    </Modal>
  );

  const renderRequiredPhotosTakenModal = () => (
    <Modal
      isOpen={requiredPhotosTakenModalDisplayed}
      primaryButtonLabel={PHOTO_HUB_PHOTOS_TAKEN_MODAL_PRIMARY_BUTTON}
      primaryButtonHandler={confirmFinish}
      secondaryButtonHandler={hideRequiredPhotosTakenModal}
      secondaryButtonLabel={PHOTO_HUB_PHOTOS_TAKEN_MODAL_SECONDARY_BUTTON}
      centerContent
    >
      <div>
        <div className="auto-left-right-margin">
          <CircleImage className="photohub-required-photo-modal-icon-image">
            <img
              src={photosTakenIcon}
              alt={PHOTO_HUB_PHOTOS_TAKEN_MODAL_TITLE}
            />
          </CircleImage>
        </div>

        <Heading type="h3-light" className="auto-left-right-margin">
          {PHOTO_HUB_PHOTOS_TAKEN_MODAL_TITLE}
        </Heading>

        <BodyText>{PHOTO_HUB_PHOTOS_TAKEN_MODAL_SUBTITLE}</BodyText>
      </div>
    </Modal>
  );

  const renderPhotoHubSections = () => {
    if (!retake) {
      return (
        <>
          <PhotoHubSection
            title={PHOTO_HUB_SECTION_GENERAL_REQUIRED_PHOTOS_TITLE}
            subtitle={PHOTO_HUB_SECTION_GENERAL_REQUIRED_PHOTOS_SUBTITLE}
            navigate={navigate}
            takenPhotos={getGeneralRequiredPhotosTaken}
            photosConfig={getGeneralRequiredPhotos()}
          />

          <div className="photohub-section-break" />

          <PhotoHubSection
            title={PHOTO_HUB_SECTION_DAMAGE_REQUIRED_PHOTOS_TITLE}
            subtitle={PHOTO_HUB_SECTION_DAMAGE_REQUIRED_PHOTOS_SUBTITLE}
            navigate={navigate}
            takenPhotos={getDamageRequiredPhotosTaken}
            photosConfig={getDamageRequiredPhotos()}
          />

          <div className="photohub-section-break" />
        </>
      );
    }
    return (
      <PhotoHubSection
        title={PHOTO_HUB_SECTION_RETAKE_PHOTOS_TITLE}
        subtitle={PHOTO_HUB_SECTION_RETAKE_PHOTOS_SUBTITLE}
        navigate={navigate}
        takenPhotos={requiredPhotosTaken}
        photosConfig={buildRequiredPhotos()}
      />
    );
  };

  return (
    <div className="adcl-screen">
      {renderFinishConfirmationModal()}
      {renderRequiredPhotosTakenModal()}

      <AppHeader displaySubtitle />

      <div className="adcl-content photohub-content">
        <Content>
          <Heading
            type="h3-bold"
            className="photohub-title auto-left-right-margin"
          >
            {PHOTO_HUB_TITLE}
          </Heading>

          <Heading
            type="h4-light"
            className="photohub-subtitle auto-left-right-margin"
          >
            {PHOTO_HUB_SUBTITLE}
          </Heading>

          {renderHintsToggle()}
          {renderTestDataButton()}
        </Content>

        {renderPhotoHubSections()}

        {!retake && (
          <>
            <Content>
              <OptionalPhotosSection
                navigate={navigate}
                optionalPhotosTaken={optionalPhotosTaken}
                deletePhoto={deletePhoto}
              />
            </Content>

            <div className="photohub-section-break" />

            <Content>
              <DamageDescriptionCard />
            </Content>
          </>
        )}

        {renderFooterContents()}
      </div>
      <AppFooter />
    </div>
  );
};

PhotoHub.propTypes = {
  settings: PropTypes.shape({
    displayHints: PropTypes.bool.isRequired,
    retake: PropTypes.shape({
      accessories: PropTypes.arrayOf(PropTypes.string).isRequired,
      required: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
    environment: PropTypes.shape({
      enablePhotoAutofill: PropTypes.bool.isRequired,
    }),
  }).isRequired,
  photoHubActions: PhotoHubActions.PropertyTypes.isRequired,
  settingsActions: SettingsActions.PropertyTypes.isRequired,
  requiredPhotosTaken: PropTypes.arrayOf(PropTypes.any).isRequired,
  optionalPhotosTaken: PropTypes.arrayOf(PropTypes.any).isRequired,
};

const mapStateToProps = ({
  settings,
  photos: { requiredPhotosTaken, optionalPhotosTaken },
}) => ({
  settings,
  requiredPhotosTaken,
  optionalPhotosTaken,
});

const mapDispatchToProps = dispatch => ({
  photoHubActions: bindActionCreators(PhotoHubActions, dispatch),
  settingsActions: bindActionCreators(SettingsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PhotoHub);
