import { Content, Heading } from '@lmig/lmds-react';
import React from 'react';
import { AppHeader } from '../../components';
import localizedStrings from '../../utils/localizedStrings';

const { PAGE_NOT_FOUND_ERROR_MESSAGE } = localizedStrings;

const PageNotFound = () => (
  <div className="adcl-screen">
    <AppHeader displaySubtitle />
    <Content>
      <Heading type="h3-light" className="auto-left-right-margin">
        {PAGE_NOT_FOUND_ERROR_MESSAGE}
      </Heading>
    </Content>
  </div>
);

export default PageNotFound;
