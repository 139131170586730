import constants from '@lmig-latam/adcl-common-lib/constants/constants';
import { store } from '../configureStore';
import history from '../history';
import { logcodes, logger } from '../logger';
import { INVALID_STATUS } from '../navigationConstants';
import { UPDATE_INVALID_STATUS } from '../../actions/ActionTypes';

const {
  STATUS_TYPES: {
    STATUS_AGENT_REVIEW,
    STATUS_AGENT_IN_PROGRESS,
    STATUS_AGENT_APPROVED,
    STATUS_AGENT_REJECTED,
    STATUS_EXPIRED,
  },
} = constants;

class InvalidStatusError extends Error {
  constructor(status, ...params) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, InvalidStatusError);
    }

    this.status = status;
    this.name = 'InvalidStatusError';
  }
}

const getLogcode = status => {
  switch (status) {
    case STATUS_AGENT_IN_PROGRESS:
    case STATUS_AGENT_REVIEW:
      return logcodes.CANAV130;
    case STATUS_AGENT_APPROVED:
      return logcodes.CANAV140;
    case STATUS_AGENT_REJECTED:
      return logcodes.CANAV150;
    case STATUS_EXPIRED:
      return logcodes.CANAV160;
    default:
      return logcodes.CANAV170;
  }
};

const handleInvalidStatusError = invalidStatusError => {
  const { push } = history;
  const { status } = invalidStatusError;

  store.dispatch({
    type: UPDATE_INVALID_STATUS,
    value: status,
  });

  logger.log(getLogcode(status), {
    to: INVALID_STATUS,
    from: history.location.pathname,
  });

  window.scrollTo(0, 0);
  push(INVALID_STATUS);
};

export { handleInvalidStatusError, InvalidStatusError };
