import React, { useEffect } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import {
  GenericError,
  InvalidStatus,
  NonMobileError,
  PageNotFound,
  PhoneNumberConfirmation,
  PhotoHub,
  ProcessComplete,
  TermsConditions,
} from '..';
import { isMobileOrTablet } from '../../utils';
import {
  checkForTokens,
  handleTokenMissingError,
} from '../../utils/customErrors';
import history from '../../utils/history';
import {
  DEFAULT,
  ERROR_GENERIC,
  INVALID_STATUS,
  PHOTO_HUB,
  PROCESS_COMPLETE_ORIGINAL,
  PROCESS_COMPLETE,
  TERMS_CONDITIONS,
} from '../../utils/navigationConstants';

const App = () => {
  useEffect(() => {
    try {
      checkForTokens();
    } catch (error) {
      handleTokenMissingError(error);
    }
  });

  if (!isMobileOrTablet()) {
    return (
      <Router history={history}>
        <Switch>
          <Route>
            <NonMobileError />
          </Route>
        </Switch>
      </Router>
    );
  }

  return (
    <Router history={history}>
      <Switch>
        <Route exact path={DEFAULT}>
          <PhoneNumberConfirmation />
        </Route>
        <Route exact path={TERMS_CONDITIONS}>
          <TermsConditions />
        </Route>
        <Route exact path={PHOTO_HUB}>
          <PhotoHub />
        </Route>
        <Route path={PROCESS_COMPLETE_ORIGINAL}>
          <ProcessComplete />
        </Route>
        <Route path={PROCESS_COMPLETE}>
          <ProcessComplete />
        </Route>
        <Route exact path={ERROR_GENERIC}>
          <GenericError />
        </Route>
        <Route exact path={INVALID_STATUS}>
          <InvalidStatus />
        </Route>
        <Route>
          <PageNotFound />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
