import React from 'react';
import {
  BodyText,
  Disclaimer,
  GridCol,
  GridRow,
  IconAdd,
} from '@lmig/lmds-react';

import PropTypes from 'prop-types';
import { IconButton, InlineLoader, Panel } from '..';
import localizedStrings from '../../utils/localizedStrings';

const {
  PHOTO_HUB_OPTIONAL_SECTION_TITLE,
  PHOTO_HUB_OPTIONAL_SECTION_PANEL_TITLE,
  PHOTO_HUB_OPTIONAL_SECTION_PANEL_SUBTITLE,
} = localizedStrings;

const optionalSectionPhotos = {
  title: PHOTO_HUB_OPTIONAL_SECTION_TITLE,
  section: {
    title: PHOTO_HUB_OPTIONAL_SECTION_PANEL_TITLE,
    subtitle: PHOTO_HUB_OPTIONAL_SECTION_PANEL_SUBTITLE,
  },
};

function OptionalSectionPanel({ showOptionalModal, inlineLoaderDisplayed }) {
  const { key, title, section, modal } = optionalSectionPhotos;

  return (
    <div>
      <BodyText className="photo-hub-section-title">{title}</BodyText>
      <Panel>
        <GridRow vAlign="middle">
          <GridCol>
            <BodyText className="card-container" type="lead">
              {section.title}
            </BodyText>
          </GridCol>
        </GridRow>

        <GridRow vAlign="middle">
          <GridCol>
            <Disclaimer className="gray-text float-left">
              {section.subtitle}
            </Disclaimer>
          </GridCol>

          <GridCol base={2}>
            <IconButton
              IconComponent={IconAdd}
              onClick={() => showOptionalModal(modal, key)}
            />
          </GridCol>
        </GridRow>

        <GridRow>
          <GridCol>
            <div className="photo-loader">
              <InlineLoader
                isLoading={inlineLoaderDisplayed}
                showAnimation={false}
              />
            </div>
          </GridCol>
        </GridRow>
      </Panel>
    </div>
  );
}

OptionalSectionPanel.propTypes = {
  dataSection: PropTypes.shape({
    key: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    section: PropTypes.shape({
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
    }).isRequired,
    modal: PropTypes.shape({
      title: PropTypes.string.isRequired,
      inputDescription: PropTypes.string.isRequired,
      inputHint: PropTypes.string.isRequired,
      primaryButton: PropTypes.string.isRequired,
      secundaryButton: PropTypes.string.isRequired,
      isTakePhoto: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
  showOptionalModal: PropTypes.func.isRequired,
  inlineLoaderDisplayed: PropTypes.bool.isRequired,
};

export default OptionalSectionPanel;
