// PHOTO HUB ACTIONS
export const DELETE_PHOTO = 'DELETE_PHOTO';

// TAKE PHOTO ACTIONS
export const STORE_PHOTO = 'STORE_PHOTO';

// SETTINGS ACTIONS
export const SHOW_APP_LOADER = 'SHOW_APP_LOADER';
export const HIDE_APP_LOADER = 'HIDE_APP_LOADER';
export const UPDATE_DISPLAY_HINTS = 'UPDATE_DISPLAY_HINTS';
export const UPDATE_RETAKE = 'UPDATE_RETAKE';
export const UPDATE_RESUME = 'UPDATE_RESUME';
export const UPDATE_INVALID_STATUS = 'UPDATE_INVALID_STATUS';

// PHONE NUMBER CONFIRMATION ACTIONS
export const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS';

export const SAVE_DAMAGE_DESCRIPTION = 'SAVE_DAMAGE_DESCRIPTION';
export const DELETE_DAMAGE_DESCRIPTION = 'DELETE_DAMAGE_DESCRIPTION';
