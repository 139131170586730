import { BodyText, Heading, IconAlert } from '@lmig/lmds-react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CircleImage, Modal } from '..';
import { PhotoHubActions } from '../../actions';
import { getRequiredPhoto } from '../../config/requiredPhotos';
import { generatePhotoObjects } from '../../utils';
import localizedStrings from '../../utils/localizedStrings';

const {
  PHOTO_HUB_PHOTO_UPLOAD_FAILURE_MODAL_TITLE,
  PHOTO_HUB_PHOTO_UPLOAD_FAILURE_MODAL_SUBTITLE,
  PHOTO_HUB_PHOTO_UPLOAD_FAILURE_MODAL_PRIMARY_BUTTON_LABEL,
} = localizedStrings;

const CapturePhoto = ({
  innerRef,
  photoHubActions,
  photoId,
  title,
  showInlineLoader,
  hideOptionalPhotoModal,
  retake,
}) => {
  let isOptional = !getRequiredPhoto(photoId);

  const [
    photoUploadFailureModalDisplayed,
    setPhotoUploadFailureModalDisplayed,
  ] = useState(false);
  const [photoToRetryUpload, setPhotoToRetryUpload] = useState();
  const [photoToRetryDisplay, setPhotoToRetryDisplay] = useState();

  const hidePhotoUploadFailureModal = () => {
    setPhotoUploadFailureModalDisplayed(false);
  };

  const showPhotoUploadFailureModal = () => {
    setPhotoUploadFailureModalDisplayed(true);
  };

  const uploadPhotoHandler = async (photoToUpload, photoToDisplay) => {
    try {
      await photoHubActions.uploadPhoto(photoToUpload, photoToDisplay);
    } catch (error) {
      showPhotoUploadFailureModal();
    } finally {
      showInlineLoader(false);
    }
  };

  const retryPhotoUpload = async () => {
    hidePhotoUploadFailureModal();
    uploadPhotoHandler(photoToRetryUpload, photoToRetryDisplay);
  };

  const onChange = async ({ currentTarget: { files = [] } }) => {
    showInlineLoader(true);

    let isConditionallyRequired = false;

    if (retake) {
      isOptional = false;
      isConditionallyRequired = false;
    }

    // surround in try catch for wrong file type
    const { photoToUpload, photoToDisplay } = await generatePhotoObjects(
      files[0],
      photoId,
      isOptional,
      isConditionallyRequired,
      title,
    );

    hideOptionalPhotoModal();

    // Store photos in state incase we need to retry
    setPhotoToRetryUpload(photoToUpload);
    setPhotoToRetryDisplay(photoToDisplay);

    await uploadPhotoHandler(photoToUpload, photoToDisplay);
  };

  const renderUploadFaliureModal = () => (
    <Modal
      isOpen={photoUploadFailureModalDisplayed}
      primaryButtonLabel={
        PHOTO_HUB_PHOTO_UPLOAD_FAILURE_MODAL_PRIMARY_BUTTON_LABEL
      }
      primaryButtonHandler={retryPhotoUpload}
      backButtonHandler={hidePhotoUploadFailureModal}
      centerContent
    >
      <div className="photohub-upload-modal">
        <Heading type="h3-light" className="auto-left-right-margin">
          {PHOTO_HUB_PHOTO_UPLOAD_FAILURE_MODAL_TITLE}
        </Heading>
        <BodyText>{PHOTO_HUB_PHOTO_UPLOAD_FAILURE_MODAL_SUBTITLE}</BodyText>
        <div className="photohub-upload-modal-image auto-left-right-margin">
          <CircleImage>
            <IconAlert sizing="auto" />
          </CircleImage>
        </div>
      </div>
    </Modal>
  );

  const inputProps = {
    className: 'adcl-hide',
    type: 'file',
    ref: innerRef,
    accept: 'image/*',
    onChange,
  };

  // If we do not pass the capture='camera' prop, this gives the user the option
  // to select photos from their media library or camera.
  if (!isOptional) {
    inputProps.capture = 'camera';
  }

  return (
    <>
      {renderUploadFaliureModal()}
      <input {...inputProps} />
    </>
  );
};

CapturePhoto.propTypes = {
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(CapturePhoto) }),
  ]).isRequired,
  photoId: PropTypes.string,
  showInlineLoader: PropTypes.func.isRequired,
  photoHubActions: PhotoHubActions.PropertyTypes.isRequired,
  title: PropTypes.string,
  hideOptionalPhotoModal: PropTypes.func,
  retake: PropTypes.bool,
};

CapturePhoto.defaultProps = {
  hideOptionalPhotoModal: () => {},
  title: '',
  photoId: '',
  retake: false,
};

const mapStateToProps = ({ settings: { retake } }) => ({
  retake,
});

const mapDispatchToProps = dispatch => ({
  photoHubActions: bindActionCreators(PhotoHubActions, dispatch),
});

const ConnectedCapturePhoto = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CapturePhoto);

// Forwarding the supplied ref to component
export default React.forwardRef((props, ref) => (
  <ConnectedCapturePhoto innerRef={ref} {...props} />
));
